import { Checkbox, Select, TextField, Card, Button, Form, Box } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import { useMemo } from 'react';
import { useTable } from 'react-table';
import { useAuthStore, useLookupStore } from '../../../context/useStore';
import ProjectSelect from '../../../shared/components/projectSelect';
import { CardHeading } from '../../../shared/components/cards/CardHeading';

export const ApprovalCard = ({ handleSave, id, resource, approvals, setApprovals, setApprovalsDirty, resourceName, resourceID }) => {
  const { purchasetypes, users } = useLookupStore();
  const { user } = useAuthStore();

  let approvalInterface = {
    Bedrag: resource.Bedragfaktuur,
    Goedgekeurd: false
  };

  if (resourceName === 'Invoice')
    approvalInterface = { ...approvalInterface, ProjectFactuurInID: null, ProjectID: null, FactuurInID: resource[resourceID], FactuurTypeID: 1, GoedgekeurdDoorID: user.id };
  if (resourceName === 'Creditnote') approvalInterface = { ...approvalInterface, ApprovalID: null, CreditnotaInID: resource[resourceID], GoedgekeurdDoorGebruikersID: user.id };

  const addEmptyLine = () => {
    setApprovals((old) => [...old, approvalInterface]);
    setApprovalsDirty(true);
  };

  const removeLineByIndex = (rowIndex) => {
    setApprovals((old) => old.filter((row, index) => index !== rowIndex));
    setApprovalsDirty(true);
  };

  const lineUpdate = (value, columnId, rowIndex) => {
    setApprovals((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
            lineIsDirty: true
          };
        }
        return row;
      })
    );
    setApprovalsDirty(true);
  };

  let approvalColumns = [
    {
      Header: 'Value',
      accessor: 'Bedrag',
      Cell: ({ row }) => <TextField id="approvalValue" onChange={(value) => lineUpdate(value, 'Bedrag', row.index)} prefix="€" value={String(row.values.Bedrag)} type="number" />
    },
    {
      Header: 'Approved',
      accessor: 'Goedgekeurd',
      width: 5,
      textAlign: 'center',
      Cell: ({ row }) => <Checkbox onChange={(value) => lineUpdate(value, 'Goedgekeurd', row.index)} checked={row.values.Goedgekeurd} />
    },
    {
      Header: 'Remove',
      accessor: resourceID,
      width: 5,
      textAlign: 'center',
      Cell: ({ row }) => <Button size="large" onClick={() => removeLineByIndex(row.index)} icon={DeleteIcon} />
    }
  ];

  if (resourceName === 'Invoice') {
    approvalColumns = [
      {
        Header: 'Type',
        accessor: 'FactuurTypeID',
        maxWidth: 200,
        Cell: ({ row }) => {
          return (
            <Select
              id="selectType"
              onChange={(value) => lineUpdate(value, 'FactuurTypeID', row.index)}
              value={parseInt(row.values.FactuurTypeID)}
              options={purchasetypes
                .sort((a, b) => (a.BestellingType > b.BestellingType ? 1 : a.BestellingType < b.BestellingType ? -1 : 0))
                .map((pt) => {
                  return { value: pt.BestellingTypeID, label: pt.BestellingType };
                })}
            />
          );
        }
      },
      ...approvalColumns.slice(0, 1),
      {
        Header: 'Approval by',
        accessor: 'GoedgekeurdDoorID',
        Cell: ({ row }) => {
          return (
            <Select
              onChange={(value) => lineUpdate(value, 'GoedgekeurdDoorID', row.index)}
              value={parseInt(row.values.GoedgekeurdDoorID)}
              options={users
                .filter((user) => user.Vennootschap === 1)
                .filter((user) => !user.Inactief)
                .sort((a, b) => (a.Afkorting > b.Afkorting ? 1 : a.Afkorting < b.Afkorting ? -1 : 0))
                .map((u) => {
                  return { value: u.GebruikerID, label: u.Afkorting };
                })}
            />
          );
        }
      },
      {
        Header: 'Project',
        accessor: 'ProjectID',
        width: 600,
        Cell: ({ row }) => <ProjectSelect value={row.values.ProjectID} onChange={lineUpdate} id="ProjectID" rowIndex={row.index} />
      },
      ...approvalColumns.slice(1)
    ];
  }

  if (resourceName === 'Creditnote') {
    approvalColumns = [
      ...approvalColumns.slice(0, 1),
      {
        Header: 'Approval by',
        accessor: 'GoedgekeurdDoorGebruikersID',
        Cell: ({ row }) => {
          return (
            <Select
              onChange={(value) => lineUpdate(value, 'GoedgekeurdDoorGebruikersID', row.index)}
              value={parseInt(row.values.GoedgekeurdDoorGebruikersID)}
              options={users
                .filter((user) => user.Vennootschap === 1)
                .filter((user) => !user.Inactief)
                .sort((a, b) => (a.Afkorting > b.Afkorting ? 1 : a.Afkorting < b.Afkorting ? -1 : 0))
                .map((u) => {
                  return { value: u.GebruikerID, label: u.Afkorting };
                })}
            />
          );
        }
      },
      ...approvalColumns.slice(1)
    ];
  }

  const data = useMemo(() => approvals, [approvals]);
  const columns = useMemo(
    () => approvalColumns,
    // eslint-disable-next-line
    []
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <Form onSubmit={handleSave}>
      <Card>
        <CardHeading title={`${resourceName} approvals`} />
        {approvals.length > 0 && (
          <table style={{ width: resourceName === 'Invoice' ? '100%' : '50%' }} {...getTableProps()} size="sm">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr style={{ textAlign: 'left' }} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps({ style: { maxWidth: column.maxWidth, minWidth: column.minWidth, width: column.width, textAlign: column.textAlign } })}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps({ style: { maxWidth: cell.column.maxWidth, minWidth: cell.column.minWidth, width: cell.column.width, textAlign: cell.column.textAlign } })}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <Box paddingBlockStart="300">
          <Button id="addApproval" disabled={id === 'new'} onClick={addEmptyLine}>
            Add approval
          </Button>
        </Box>
      </Card>
    </Form>
  );
};
