// form that adds and edits interactions

import React, { useState, useEffect, useMemo } from 'react';
import { Form, FormLayout, TextField, Select, Button, Text } from '@shopify/polaris';
import { postRequest, getRequest, putRequest } from '../../../shared/components/functions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CompanySelect from '../../../shared/components/companySelect';
import ContactSelect from '../../../shared/components/contactSelect';

export const interactionTypeGroups = {
  visits: {
    types: ['visit_site', 'visit_intern', 'appointment_intern', 'appointment_extern'],
    color: 'success'
  },
  calls: {
    types: ['incoming_call', 'outgoing_call', 'cold_call', 'follow_up_call', 'inbound_support_call', 'outbound_support_call'],
    color: 'info'
  },
  digital: {
    types: ['email', 'marketing_email', 'video_meeting'],
    color: 'attention'
  },
  presentations: {
    types: ['demo_presentation', 'trade_show_encounter'],
    color: 'new'
  },
  other: {
    types: ['note'],
    color: 'critical'
  }
};

export const interactionTypes = [
  // Visits
  { label: 'Site Visit', value: 'visit_site' },
  { label: 'Internal Visit', value: 'visit_intern' },

  // Appointments
  { label: 'Internal Appointment', value: 'appointment_intern' },
  { label: 'External Appointment', value: 'appointment_extern' },

  // Calls
  { label: 'Incoming Call', value: 'incoming_call' },
  { label: 'Outgoing Call', value: 'outgoing_call' },
  { label: 'Cold Call', value: 'cold_call' },
  { label: 'Follow-up Call', value: 'follow_up_call' },
  { label: 'Inbound Support Call', value: 'inbound_support_call' },
  { label: 'Outbound Support Call', value: 'outbound_support_call' },

  // Digital Communications
  { label: 'Email', value: 'email' },
  { label: 'Marketing Email', value: 'marketing_email' },
  { label: 'Video Meeting', value: 'video_meeting' },

  // Presentations & Events
  { label: 'Demo/Presentation', value: 'demo_presentation' },
  { label: 'Trade Show Encounter', value: 'trade_show_encounter' },

  // Other
  { label: 'Note', value: 'note' }
];

const InteractionForm = ({ onSubmit, interaction, isNew = false }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState(null);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [lastSavedData, setLastSavedData] = useState(null);
  const [saveStatus, setSaveStatus] = useState(''); // 'saving', 'saved', or error message

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`; // Format: "YYYY-MM-DDTHH:mm"
  };

  const initialValues = {
    type: '',
    description: '',
    event_date: getCurrentDateTime(),
    start_time: '',
    end_time: '',
    company_id: '',
    company_contact_id: '',
    project_id: '',
    user_id: '',
    title: ''
  };

  const [formData, setFormData] = useState({ ...initialValues });
  const [companyContacts, setCompanyContacts] = useState([]);
  const [companyData, setCompanyData] = useState(null);
  const [contactData, setContactData] = useState(null);

  // Load initial data when in edit mode or with prefilled data
  useEffect(() => {
    if (interaction) {
      console.log('Initializing form with:', interaction);

      // Create a copy of the interaction data
      const data = { ...interaction };

      // Format the event date
      if (!data.event_date) {
        data.event_date = getCurrentDateTime();
      } else {
        const date = new Date(data.event_date);
        data.event_date = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T${String(date.getHours()).padStart(2, '0')}:${String(
          date.getMinutes()
        ).padStart(2, '0')}`;
      }

      // Format times if they exist
      if (data.start_time) {
        data.start_time = new Date(data.start_time).toISOString().slice(0, 16);
      }
      if (data.end_time) {
        data.end_time = new Date(data.end_time).toISOString().slice(0, 16);
      }

      // Set the form data
      setFormData(data);

      // Load company data if we have a company_id
      if (data.company_id) {
        handleFieldChange('company_id', data.company_id);
      }

      // Load contact data if we have a company_contact_id
      if (data.company_contact_id) {
        handleFieldChange('company_contact_id', data.company_contact_id);
      }
    } else {
      // Set default values for new interaction
      setFormData({
        ...initialValues,
        event_date: getCurrentDateTime()
      });
    }
  }, [interaction]);

  // Load company data when company_id is set
  useEffect(() => {
    async function fetchCompanyData() {
      if (formData.company_id) {
        try {
          const company = await getRequest(`/api/companies/${formData.company_id}`);
          setCompanyData(company);
        } catch (error) {
          console.error('Error fetching company data:', error);
          setCompanyData(null);
        }
      } else {
        setCompanyData(null);
      }
    }
    fetchCompanyData();
  }, [formData.company_id]);

  // Load contact data when company_contact_id is set
  useEffect(() => {
    async function fetchContactData() {
      if (formData.company_contact_id) {
        try {
          const contact = await getRequest(`/api/contacts/${formData.company_contact_id}`);
          if (contact && contact.length > 0) {
            setContactData(contact[0]);

            // If company_id is not set but contact has a company, set it
            if (!formData.company_id && contact[0].KLANR) {
              setFormData((prev) => ({
                ...prev,
                company_id: contact[0].KLANR
              }));
            }
          } else {
            setContactData(null);
          }
        } catch (error) {
          console.error('Error fetching contact data:', error);
          setContactData(null);
        }
      } else {
        setContactData(null);
      }
    }
    fetchContactData();
  }, [formData.company_contact_id]);

  // Load company contacts when company is selected
  useEffect(() => {
    async function fetchData() {
      if (formData.company_id) {
        const contacts = await getRequest(`/api/contacts?limit=10000&FIRNR=${formData.company_id}`);
        setCompanyContacts(contacts.filter((contact) => contact.DISCONTINUED !== true));
      } else {
        setCompanyContacts([]);
      }
    }
    fetchData();
  }, [formData.company_id]);

  const handleFieldChange = (field, value) => {
    setFormData((prev) => {
      const newData = { ...prev, [field]: value };
      if (field === 'company_id') {
        newData.company_contact_id = '';
      }
      return newData;
    });
    setSaveStatus('');
  };

  const handleFieldBlur = async (field, value) => {
    if (!formData.id || isNew) return; // Don't auto-save for new interactions

    // Don't save if the value hasn't changed
    if (lastSavedData && lastSavedData[field] === value) return;

    try {
      setIsSubmitting(true);
      setSaveStatus('saving');
      const dataToSubmit = {
        ...formData,
        [field]: value,
        event_date: new Date(formData.event_date).toISOString(),
        start_time: formData.start_time ? new Date(formData.start_time).toISOString() : null,
        end_time: formData.end_time ? new Date(formData.end_time).toISOString() : null
      };

      await putRequest(`/api/interactions/${formData.id}`, dataToSubmit);
      setLastSavedData(dataToSubmit);
      setSaveStatus('saved');

      // Clear the "saved" message after 2 seconds
      setTimeout(() => {
        setSaveStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error auto-saving interaction:', error);
      setSaveStatus('Error saving changes');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSubmit = async () => {
    if (!formData.description || !formData.event_date || !formData.type || !formData.title) {
      window.alert('Please fill in all required fields');
      return;
    }

    const dataToSubmit = {
      ...formData,
      event_date: new Date(formData.event_date).toISOString(),
      start_time: formData.start_time ? new Date(formData.start_time).toISOString() : null,
      end_time: formData.end_time ? new Date(formData.end_time).toISOString() : null
    };

    try {
      setIsSubmitting(true);
      if (!isNew && formData.id) {
        await putRequest(`/api/interactions/${formData.id}`, dataToSubmit);
        setLastSavedData(dataToSubmit);
      } else {
        if (dataToSubmit.id) {
          delete dataToSubmit.id;
        }
        await postRequest('/api/interactions', dataToSubmit);
      }
      if (onSubmit) {
        await onSubmit(dataToSubmit);
      }
    } catch (error) {
      console.error('Error saving interaction:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFileUpload = async (files, acceptedFiles) => {
    // Handle drag and drop from email client
    if (files?.[0]?.kind === 'file') {
      setIsProcessingFile(true);
      try {
        const file = files[0].getAsFile();
        if (!file) return;

        setFile(file);
        const formData = new FormData();
        formData.append('file', file);

        const response = await fetch('/api/openai/process-file', {
          method: 'POST',
          body: formData
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setFormData((prev) => ({
          ...prev,
          description: data.summary || 'Error: No summary received'
        }));
      } catch (error) {
        console.error('Error processing file:', error);
        alert(`Error processing file: ${error.message}`);
      } finally {
        setIsProcessingFile(false);
      }
      return;
    }

    // Regular file upload handling
    if (!files?.[0]) return;

    setIsProcessingFile(true);
    setFile(files[0]);

    const formData = new FormData();
    formData.append('file', files[0]);

    try {
      const response = await fetch('/api/openai/process-file', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setFormData((prev) => ({
        ...prev,
        description: data.summary || 'Error: No summary received'
      }));
    } catch (error) {
      console.error('Error processing file:', error);
      alert(`Error processing file: ${error.message}`);
    } finally {
      setIsProcessingFile(false);
    }
  };

  // Memoize Quill configuration to prevent unnecessary re-renders
  const quillConfig = useMemo(
    () => ({
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block'], [{ list: 'ordered' }, { list: 'bullet' }], [{ indent: '-1' }, { indent: '+1' }], ['clean']],
        clipboard: {
          matchVisual: false // Prevents unwanted DOM mutation events
        }
      },
      formats: ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'list', 'bullet', 'indent']
    }),
    []
  );

  return (
    <Form>
      <FormLayout>
        {!isNew && (
          <div
            style={{
              position: 'fixed',
              top: '16px',
              right: '16px',
              padding: '8px 16px',
              borderRadius: '4px',
              backgroundColor: saveStatus === 'saving' ? '#f4f6f8' : saveStatus === 'saved' ? '#E3F1DF' : saveStatus ? '#FED3D1' : 'transparent',
              color: saveStatus === 'saving' ? '#637381' : saveStatus === 'saved' ? '#108043' : '#DE3618',
              transition: 'all 0.2s ease',
              opacity: saveStatus ? 1 : 0
            }}
          >
            <Text>{saveStatus === 'saving' ? 'Saving...' : saveStatus === 'saved' ? 'Changes saved' : saveStatus || ''}</Text>
          </div>
        )}

        <FormLayout.Group condensed>
          <div style={{ flex: '1 1 50%' }}>
            <Select
              label="Type"
              options={interactionTypes}
              value={formData.type}
              onChange={(value) => handleFieldChange('type', value)}
              onBlur={() => handleFieldBlur('type', formData.type)}
              placeholder="Select an interaction type"
              requiredIndicator
              required
            />
          </div>
          <div style={{ flex: '1 1 50%' }}>
            <TextField
              label="Event Date"
              type="datetime-local"
              value={formData.event_date}
              onChange={(value) => handleFieldChange('event_date', value)}
              onBlur={() => handleFieldBlur('event_date', formData.event_date)}
              required
            />
          </div>
        </FormLayout.Group>
        <FormLayout.Group>
          <div style={{ flex: '1 1 50%' }}>
            <CompanySelect
              label="Company"
              value={formData.company_id}
              onChange={(value) => {
                handleFieldChange('company_id', value);
                if (!isNew) {
                  handleFieldBlur('company_id', value);
                }
              }}
            />
          </div>

          <div style={{ flex: '1 1 50%' }}>
            <ContactSelect
              disabled={!formData.company_id}
              FIRNR={formData.company_id}
              id="company_contact_id"
              label="Customer contact"
              value={formData.company_contact_id}
              onChange={(value) => {
                handleFieldChange('company_contact_id', value);
                handleFieldBlur('company_contact_id', value);
              }}
              contacts={companyContacts}
            />
          </div>
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Title"
            value={formData.title}
            onChange={(value) => handleFieldChange('title', value)}
            onBlur={() => handleFieldBlur('title', formData.title)}
            requiredIndicator
            required
            autoComplete="off"
          />
        </FormLayout.Group>

        <div style={{ marginBottom: '1rem' }}>
          <div style={{ marginBottom: '0.4rem' }}>
            <label style={{ fontWeight: '500' }}>
              Description<span style={{ color: 'rgb(220, 53, 69)', marginLeft: '2px' }}>*</span>
            </label>
          </div>
          <ReactQuill
            theme="snow"
            value={formData.description}
            onChange={(value) => handleFieldChange('description', value)}
            onBlur={() => handleFieldBlur('description', formData.description)}
            modules={quillConfig.modules}
            formats={quillConfig.formats}
            style={{ height: '500px', marginBottom: '2rem' }}
            preserveWhitespace={true}
            readOnly={isProcessingFile}
          />
        </div>

        <FormLayout.Group>
          <TextField
            label="Start Time"
            type="datetime-local"
            value={formData.start_time || ''}
            onChange={(value) => handleFieldChange('start_time', value)}
            onBlur={() => handleFieldBlur('start_time', formData.start_time)}
          />
          <TextField
            label="End Time"
            type="datetime-local"
            value={formData.end_time || ''}
            onChange={(value) => handleFieldChange('end_time', value)}
            onBlur={() => handleFieldBlur('end_time', formData.end_time)}
          />
        </FormLayout.Group>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button primary onClick={handleSubmit} loading={isSubmitting} disabled={isSubmitting}>
            {isNew ? 'Create Interaction' : 'Save Changes'}
          </Button>
        </div>
      </FormLayout>
    </Form>
  );
};

export default InteractionForm;
