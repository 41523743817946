import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Page, Card, Button, TextField, FormLayout, Form, Modal, Text, BlockStack, Box, Banner, Icon, Popover, ActionList, Checkbox, ProgressBar, Tooltip } from '@shopify/polaris';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { formatDate, getRequest, putRequest, postRequest, deleteRequest } from '../../shared/components/functions';
import { useAuthStore, useLookupStore } from '../../context/useStore';
import { CardHeading } from '../../shared/components/cards/CardHeading';
import CompanySelect from '../../shared/components/companySelect';
import { PersonIcon, InventoryIcon, MenuVerticalIcon, DeleteIcon, MinimizeIcon, MaximizeIcon } from '@shopify/polaris-icons';
import { ContactReactSelect } from '../../shared/components/contactReactSelect';

export default function PipelinePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [pipeline, setPipeline] = useState(null);
  const [stages, setStages] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const [newStageModalOpen, setNewStageModalOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [companyContacts, setCompanyContacts] = useState([]);
  const users = useLookupStore((state) => state.users);
  const [newItem, setNewItem] = useState({
    Description: '',
    DueDate: null,
    Checklist: JSON.stringify([])
  });
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [newStage, setNewStage] = useState({
    Name: '',
    Description: ''
  });
  const [deleteStageModalOpen, setDeleteStageModalOpen] = useState(false);
  const [stageToDelete, setStageToDelete] = useState(null);
  const [deleteItemModalOpen, setDeleteItemModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [activeActionPopover, setActiveActionPopover] = useState(null);
  const [editStageModalOpen, setEditStageModalOpen] = useState(false);
  const [stageToEdit, setStageToEdit] = useState(null);
  const [activeStageActionPopover, setActiveStageActionPopover] = useState(null);
  const [editingStageId, setEditingStageId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id === 'new') {
          setPipeline({
            Name: '',
            Description: '',
            OwnerID: user.id
          });
        } else {
          const [pipelineData, stagesData, itemsData] = await Promise.all([getRequest(`/api/pipelines/${id}`), getRequest(`/api/pipelines/${id}/stages`), getRequest(`/api/pipelines/${id}/items`)]);
          setPipeline(pipelineData);
          setStages(stagesData);
          setItems(itemsData);
        }
      } catch (error) {
        console.error('Error fetching pipeline data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, user.id]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId, type } = result;

    // If the destination is the same as the source and the index is the same, do nothing
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // Handle stage reordering
    if (type === 'STAGE') {
      if (source.index === destination.index) return;

      try {
        // Create new stages array with updated order
        const newStages = Array.from(stages);
        const [removed] = newStages.splice(source.index, 1);
        newStages.splice(destination.index, 0, removed);

        // Update order property for all affected stages
        const updatedStages = newStages.map((stage, index) => ({
          ...stage,
          Order: index
        }));

        // Update backend
        await putRequest(`/api/pipelines/pipeline-stages/reorder`, {
          stages: updatedStages.map((s) => ({
            id: s.PipelineStageID,
            order: s.Order
          }))
        });

        // Update local state
        setStages(updatedStages);
        return;
      } catch (error) {
        console.error('Error reordering stages:', error);
        return;
      }
    }

    // Handle item movement between stages or reordering within a stage
    try {
      const movedItemId = parseInt(draggableId);
      const movedItem = items.find((item) => item.PipelineItemID === movedItemId);

      if (!movedItem) {
        console.error('Could not find the moved item');
        return;
      }

      const SEQUENCE_GAP = 1000; // Large gap to avoid frequent renumbering
      let newSequence;

      if (source.droppableId === destination.droppableId) {
        // Reordering within the same stage
        const stageId = parseInt(source.droppableId);

        // Create a copy of the items in this stage for manipulation
        const stageItems = [...items].filter((item) => item.PipelineStageID === stageId).sort((a, b) => Number(a.SortingSequence || 0) - Number(b.SortingSequence || 0));

        // Remove the moved item from the array
        const itemIndex = stageItems.findIndex((item) => item.PipelineItemID === movedItemId);
        if (itemIndex !== -1) {
          stageItems.splice(itemIndex, 1);
        }

        // Calculate new sequence based on destination index
        if (destination.index === 0) {
          // Item moved to the top
          newSequence = stageItems.length > 0 ? Number(stageItems[0].SortingSequence || 0) - SEQUENCE_GAP : 0;
        } else if (destination.index >= stageItems.length) {
          // Item moved to the bottom
          newSequence = stageItems.length > 0 ? Number(stageItems[stageItems.length - 1].SortingSequence || 0) + SEQUENCE_GAP : SEQUENCE_GAP;
        } else {
          // Item moved to the middle - place it halfway between adjacent items
          const prevSequence = Number(stageItems[destination.index - 1].SortingSequence || 0);
          const nextSequence = Number(stageItems[destination.index].SortingSequence || 0);
          // Use floating point division to handle equal sequences better
          newSequence = prevSequence + (nextSequence - prevSequence) / 2;
        }

        console.log(`Moving item ${movedItemId} within stage ${stageId} to sequence ${newSequence}`);

        // Update the backend with the new sequence
        await putRequest(`/api/pipelines/pipeline-items/${movedItemId}`, {
          SortingSequence: newSequence
        });
      } else {
        // Moving item between stages
        const sourceStageId = parseInt(source.droppableId);
        const targetStageId = parseInt(destination.droppableId);

        // Create a copy of the items in the target stage for manipulation
        const targetStageItems = [...items].filter((item) => item.PipelineStageID === targetStageId).sort((a, b) => Number(a.SortingSequence || 0) - Number(b.SortingSequence || 0));

        // Calculate new sequence based on destination index
        if (targetStageItems.length === 0) {
          // First item in the stage
          newSequence = 0;
        } else if (destination.index === 0) {
          // Item moved to the top of the stage
          newSequence = Number(targetStageItems[0].SortingSequence || 0) - SEQUENCE_GAP;
        } else if (destination.index >= targetStageItems.length) {
          // Item moved to the bottom of the stage
          newSequence = Number(targetStageItems[targetStageItems.length - 1].SortingSequence || 0) + SEQUENCE_GAP;
        } else {
          // Item moved to the middle of the stage
          const prevSequence = Number(targetStageItems[destination.index - 1].SortingSequence || 0);
          const nextSequence = Number(targetStageItems[destination.index].SortingSequence || 0);
          // Use floating point division to handle equal sequences better
          newSequence = prevSequence + (nextSequence - prevSequence) / 2;
        }

        console.log(`Moving item ${movedItemId} from stage ${sourceStageId} to stage ${targetStageId} with sequence ${newSequence}`);

        // Update backend
        await putRequest(`/api/pipelines/pipeline-items/${draggableId}/move`, {
          newStageId: targetStageId,
          SortingSequence: newSequence
        });
      }

      // After updating the backend, refetch all items to ensure we have the latest data
      const updatedItems = await getRequest(`/api/pipelines/${id}/items`);
      setItems(updatedItems);
    } catch (error) {
      console.error('Error moving/reordering item:', error);
    }
  };

  const handleSavePipeline = async () => {
    try {
      if (id === 'new') {
        const newPipeline = await postRequest('/api/pipelines', pipeline);
        navigate(`/pipelines/${newPipeline.PipelineID}`);
      } else {
        await putRequest(`/api/pipelines/${id}`, pipeline);
      }
    } catch (error) {
      console.error('Error saving pipeline:', error);
    }
  };

  const handleAddItem = async () => {
    try {
      // Find the highest SortingSequence in the target stage
      const stageItems = items.filter((item) => item.PipelineStageID === newItem.PipelineStageID);
      const highestSequence = stageItems.length > 0 ? Math.max(...stageItems.map((item) => Number(item.SortingSequence || 0))) : 0;

      // Use the same SEQUENCE_GAP as in the drag and drop logic for consistency
      const SEQUENCE_GAP = 1000;

      const payload = {
        ...newItem,
        CompanyID: selectedCompanyId,
        ContactID: selectedContact?.value,
        CreatedByID: user.id,
        PipelineStageID: newItem.PipelineStageID,
        SortingSequence: highestSequence + SEQUENCE_GAP,
        Checklist: newItem.Checklist || JSON.stringify([])
      };

      console.log('Adding item with payload:', payload);
      await postRequest(`/api/pipelines/pipeline-items`, payload);

      // Refetch all items to ensure we have the latest data
      const updatedItems = await getRequest(`/api/pipelines/${id}/items`);
      setItems(updatedItems);

      setNewItemModalOpen(false);
      setNewItem({ Description: '', DueDate: null, PipelineStageID: null, Checklist: JSON.stringify([]) });
      setSelectedCompanyId(null);
      setSelectedContact(null);
      setCompanyContacts([]);
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleUpdateDescription = async (itemId, newDescription) => {
    try {
      // Update backend
      await putRequest(`/api/pipelines/pipeline-items/${itemId}`, {
        Description: newDescription
      });

      // Update local state immediately
      setItems((prevItems) => prevItems.map((item) => (item.PipelineItemID === itemId ? { ...item, Description: newDescription } : item)));

      return true;
    } catch (error) {
      console.error('Error updating description:', error);
      return false;
    }
  };

  const handleAddStage = async () => {
    try {
      const payload = {
        Name: newStage.Name,
        Description: newStage.Description,
        PipelineID: pipeline.PipelineID,
        Order: stages.length
      };

      const createdStage = await postRequest(`/api/pipelines/pipeline-stages`, payload);
      console.log('Created stage:', createdStage);

      if (!createdStage.PipelineStageID) {
        throw new Error('Created stage is missing PipelineStageID');
      }

      setStages((prevStages) => [...prevStages, createdStage]);
      setNewStageModalOpen(false);
      setNewStage({ Name: '', Description: '' });
    } catch (error) {
      console.error('Error adding stage:', error);
    }
  };

  const handleDeleteStage = async () => {
    try {
      await deleteRequest(`/api/pipelines/pipeline-stages/${stageToDelete.PipelineStageID}`);

      // Update local state
      setStages(stages.filter((stage) => stage.PipelineStageID !== stageToDelete.PipelineStageID));
      setItems(items.filter((item) => item.PipelineStageID !== stageToDelete.PipelineStageID));

      setDeleteStageModalOpen(false);
      setStageToDelete(null);
    } catch (error) {
      console.error('Error deleting stage:', error);
    }
  };

  const handleDeleteItem = async () => {
    try {
      await deleteRequest(`/api/pipelines/pipeline-items/${itemToDelete.PipelineItemID}`);

      // Update local state
      setItems(items.filter((item) => item.PipelineItemID !== itemToDelete.PipelineItemID));

      setDeleteItemModalOpen(false);
      setItemToDelete(null);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const openEditItemModal = (item) => {
    // Create a deep copy of the item to prevent state issues
    const itemToEditData = {
      ...item,
      Description: item.Description ?? '', // Use nullish coalescing to handle empty strings correctly
      Checklist: item.Checklist || JSON.stringify([])
    };
    console.log('Opening modal with item:', itemToEditData);
    setItemToEdit(itemToEditData);
    setSelectedCompanyId(item.CompanyID);

    // Fetch contacts for the company if there is one
    if (item.CompanyID) {
      getRequest(`/api/contacts?limit=10000&FIRNR=${item.CompanyID}`)
        .then((contacts) => {
          setCompanyContacts(contacts || []);
          // Set the selected contact if there is one
          if (item.ContactID) {
            const contact = contacts.find((c) => c.ContactID === item.ContactID);
            if (contact) {
              setSelectedContact({
                value: contact.ContactID,
                label: `${contact.FirstName} ${contact.LastName}`.trim()
              });
            }
          }
        })
        .catch((error) => {
          console.error('Error fetching contacts:', error);
          setCompanyContacts([]);
        });
    } else {
      setCompanyContacts([]);
      setSelectedContact(null);
    }

    setEditItemModalOpen(true);
  };

  const handleEditItem = async () => {
    try {
      if (!itemToEdit) return;

      // Prepare the payload with the edited values
      const payload = {
        Description: itemToEdit.Description,
        DueDate: itemToEdit.DueDate,
        CompanyID: selectedCompanyId,
        ContactID: selectedContact?.value,
        PipelineStageID: itemToEdit.PipelineStageID,
        SortingSequence: itemToEdit.SortingSequence,
        Checklist: itemToEdit.Checklist || JSON.stringify([])
      };

      console.log('Updating item with payload:', payload);
      await putRequest(`/api/pipelines/pipeline-items/${itemToEdit.PipelineItemID}`, payload);

      // Update local state first
      setItems((prevItems) => prevItems.map((item) => (item.PipelineItemID === itemToEdit.PipelineItemID ? { ...item, ...payload } : item)));

      // Close the modal and reset state
      setEditItemModalOpen(false);
      setItemToEdit(null);
      setSelectedCompanyId(null);
      setSelectedContact(null);
      setCompanyContacts([]);
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleEditStage = async () => {
    try {
      await putRequest(`/api/pipelines/pipeline-stages/${stageToEdit.PipelineStageID}`, {
        Name: stageToEdit.Name,
        Description: stageToEdit.Description
      });

      // Update local state
      setStages(stages.map((stage) => (stage.PipelineStageID === stageToEdit.PipelineStageID ? stageToEdit : stage)));

      setEditStageModalOpen(false);
      setStageToEdit(null);
    } catch (error) {
      console.error('Error updating stage:', error);
    }
  };

  const handleChecklistChange = async (itemId, checklist) => {
    try {
      await putRequest(`/api/pipelines/pipeline-items/${itemId}`, {
        Checklist: JSON.stringify(checklist)
      });

      // Update local state
      setItems(items.map((item) => (item.PipelineItemID === itemId ? { ...item, Checklist: JSON.stringify(checklist) } : item)));
    } catch (error) {
      console.error('Error updating checklist:', error);
    }
  };

  const handleAddChecklistItem = async (itemId) => {
    const item = items.find((i) => i.PipelineItemID === itemId);
    if (!item) return;

    const checklist = JSON.parse(item.Checklist || '[]');
    const newChecklist = [...checklist, { text: '', completed: false }];

    await handleChecklistChange(itemId, newChecklist);
  };

  const handleUpdateChecklistItem = async (itemId, index, updates) => {
    const item = items.find((i) => i.PipelineItemID === itemId);
    if (!item) return;

    const checklist = JSON.parse(item.Checklist || '[]');
    const newChecklist = checklist.map((item, i) => (i === index ? { ...item, ...updates } : item));

    await handleChecklistChange(itemId, newChecklist);
  };

  const handleDeleteChecklistItem = async (itemId, index) => {
    const item = items.find((i) => i.PipelineItemID === itemId);
    if (!item) return;

    const checklist = JSON.parse(item.Checklist || '[]');
    const newChecklist = checklist.filter((_, i) => i !== index);

    await handleChecklistChange(itemId, newChecklist);
  };

  const handleUpdateStageName = async (stageId, newName) => {
    try {
      await putRequest(`/api/pipelines/pipeline-stages/${stageId}`, {
        Name: newName
      });

      setStages((prevStages) => prevStages.map((stage) => (stage.PipelineStageID === stageId ? { ...stage, Name: newName } : stage)));

      return true;
    } catch (error) {
      console.error('Error updating stage name:', error);
      return false;
    }
  };

  if (loading) {
    return <Page title="Loading..." />;
  }

  const isOwner = true; // fixme

  console.log('user', user, pipeline?.OwnerID, user.id);

  return (
    <Page
      title={id === 'new' ? 'New Pipeline' : pipeline?.Name}
      primaryAction={
        id === 'new' ? (
          <Button primary onClick={handleSavePipeline}>
            Create Pipeline
          </Button>
        ) : null
      }
      fullWidth
    >
      {id === 'new' ? (
        <Card>
          <CardHeading title="Pipeline Details" />
          <Box>
            <Form onSubmit={handleSavePipeline}>
              <FormLayout>
                <TextField label="Name" value={pipeline.Name} onChange={(value) => setPipeline({ ...pipeline, Name: value })} autoComplete="off" required />
                <TextField label="Description" value={pipeline.Description} onChange={(value) => setPipeline({ ...pipeline, Description: value })} autoComplete="off" multiline={3} />
              </FormLayout>
            </Form>
          </Box>
        </Card>
      ) : !isOwner ? (
        <Banner tone="warning">You are viewing this pipeline in read-only mode. Only the owner can make changes.</Banner>
      ) : null}

      {id !== 'new' && (
        <Box>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="stages" direction="horizontal" type="STAGE">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                    overflowX: 'auto',
                    padding: '0',
                    position: 'relative'
                  }}
                >
                  {stages
                    .sort((a, b) => a.Order - b.Order)
                    .map((stage, index) => (
                      <Draggable key={`stage-${stage.PipelineStageID}`} draggableId={`stage-${stage.PipelineStageID}`} index={index} isDragDisabled={!isOwner}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              minWidth: '300px'
                            }}
                          >
                            <Card>
                              <div {...provided.dragHandleProps}>
                                <CardHeading
                                  title={
                                    editingStageId === stage.PipelineStageID ? (
                                      <div
                                        style={{
                                          position: 'relative',
                                          padding: '2px',
                                          margin: '-2px',
                                          background: 'var(--p-color-bg-surface)',
                                          borderRadius: '4px',
                                          boxShadow: 'var(--p-shadow-card)'
                                        }}
                                      >
                                        <TextField
                                          value={stage.Name}
                                          onChange={(value) => {
                                            setStages(stages.map((s) => (s.PipelineStageID === stage.PipelineStageID ? { ...s, Name: value } : s)));
                                          }}
                                          onFocus={(e) => {
                                            const textArea = e.target;
                                            const length = textArea.value.length;
                                            textArea.setSelectionRange(length, length);
                                          }}
                                          onBlur={async (e) => {
                                            const success = await handleUpdateStageName(stage.PipelineStageID, e.target.value);
                                            if (success) {
                                              setEditingStageId(null);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                              e.preventDefault();
                                              e.target.blur();
                                            }
                                            if (e.key === 'Escape') {
                                              setEditingStageId(null);
                                            }
                                          }}
                                          autoComplete="off"
                                          autoFocus
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        onClick={() => setEditingStageId(stage.PipelineStageID)}
                                        style={{
                                          cursor: 'text',
                                          padding: '2px',
                                          borderRadius: '4px',
                                          transition: 'background-color 0.15s ease-in-out'
                                        }}
                                        onMouseEnter={(e) => {
                                          e.currentTarget.style.backgroundColor = 'var(--p-color-bg-surface-hover)';
                                        }}
                                        onMouseLeave={(e) => {
                                          e.currentTarget.style.backgroundColor = 'transparent';
                                        }}
                                      >
                                        {stage.Name}
                                      </div>
                                    )
                                  }
                                  actions={
                                    isOwner
                                      ? [
                                          {
                                            content: (
                                              <Popover
                                                active={activeStageActionPopover === stage.PipelineStageID}
                                                activator={<Button size="micro" variant="tertiary" onClick={() => setActiveStageActionPopover(stage.PipelineStageID)} icon={MenuVerticalIcon} />}
                                                onClose={() => setActiveStageActionPopover(null)}
                                                autofocusTarget="first-node"
                                              >
                                                <ActionList
                                                  actionRole="menuitem"
                                                  items={[
                                                    {
                                                      content: 'Edit',
                                                      onAction: () => {
                                                        setActiveStageActionPopover(null);
                                                        setStageToEdit(stage);
                                                        setEditStageModalOpen(true);
                                                      }
                                                    },
                                                    {
                                                      content: 'Delete',
                                                      onAction: () => {
                                                        setActiveStageActionPopover(null);
                                                        setStageToDelete(stage);
                                                        setDeleteStageModalOpen(true);
                                                      },
                                                      destructive: true
                                                    }
                                                  ]}
                                                />
                                              </Popover>
                                            )
                                          }
                                        ]
                                      : []
                                  }
                                />
                              </div>
                              <Droppable droppableId={stage.PipelineStageID.toString()}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    style={{
                                      minHeight: '100px',
                                      position: 'relative'
                                    }}
                                  >
                                    {items
                                      .filter((item) => item.PipelineStageID === stage.PipelineStageID)
                                      .sort((a, b) => Number(a.SortingSequence || 0) - Number(b.SortingSequence || 0))
                                      .map((item, index) => (
                                        <Draggable key={item.PipelineItemID.toString()} draggableId={item.PipelineItemID.toString()} index={index} isDragDisabled={!isOwner}>
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                ...provided.draggableProps.style,
                                                marginBottom: '0.5rem',
                                                position: 'relative',
                                                zIndex: snapshot.isDragging ? 999999 : 'auto'
                                              }}
                                              onMouseEnter={(e) => {
                                                if (!snapshot.isDragging) {
                                                  const button = e.currentTarget.querySelector('.add-checklist-button');
                                                  if (button) {
                                                    button.style.maxHeight = '32px';
                                                    button.style.opacity = '1';
                                                  }
                                                }
                                              }}
                                              onMouseLeave={(e) => {
                                                const button = e.currentTarget.querySelector('.add-checklist-button');
                                                if (button) {
                                                  button.style.maxHeight = '0';
                                                  button.style.opacity = '0';
                                                }
                                              }}
                                            >
                                              <Card padding="200">
                                                <BlockStack gap="100">
                                                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                    <div style={{ flex: 1 }}>
                                                      {itemToEdit?.PipelineItemID === item.PipelineItemID ? (
                                                        <div
                                                          style={{
                                                            position: 'relative',
                                                            padding: '2px',
                                                            margin: '-2px',
                                                            background: 'var(--p-color-bg-surface)',
                                                            borderRadius: '4px',
                                                            boxShadow: 'var(--p-shadow-card)'
                                                          }}
                                                        >
                                                          <TextField
                                                            value={item.Description || ''}
                                                            onChange={(value) => {
                                                              setItems(items.map((i) => (i.PipelineItemID === item.PipelineItemID ? { ...i, Description: value } : i)));
                                                            }}
                                                            onFocus={(e) => {
                                                              const textArea = e.target;
                                                              const length = textArea.value.length;
                                                              textArea.setSelectionRange(length, length);
                                                            }}
                                                            onBlur={async (e) => {
                                                              const success = await handleUpdateDescription(item.PipelineItemID, e.target.value);
                                                              if (success) {
                                                                setItemToEdit(null);
                                                              }
                                                            }}
                                                            onKeyDown={(e) => {
                                                              if (e.key === 'Enter' && !e.shiftKey) {
                                                                e.preventDefault();
                                                                e.target.blur();
                                                              }
                                                              if (e.key === 'Escape') {
                                                                setItemToEdit(null);
                                                              }
                                                            }}
                                                            autoComplete="off"
                                                            multiline={3}
                                                            placeholder="Enter description..."
                                                            autoFocus
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div
                                                          onClick={() => setItemToEdit(item)}
                                                          style={{
                                                            cursor: 'text',
                                                            minHeight: '24px',
                                                            padding: '2px',
                                                            borderRadius: '4px',
                                                            transition: 'background-color 0.15s ease-in-out',
                                                            whiteSpace: 'pre-wrap',
                                                            wordBreak: 'break-word'
                                                          }}
                                                          onMouseEnter={(e) => {
                                                            e.currentTarget.style.backgroundColor = 'var(--p-color-bg-surface-hover)';
                                                          }}
                                                          onMouseLeave={(e) => {
                                                            e.currentTarget.style.backgroundColor = 'transparent';
                                                          }}
                                                        >
                                                          <Text as="p" variant="bodyMd" color={item.Description ? undefined : 'subdued'}>
                                                            {item.Description || 'Add description...'}
                                                          </Text>
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                                      {isOwner && (
                                                        <Popover
                                                          active={activeActionPopover === item.PipelineItemID}
                                                          activator={<Button size="micro" variant="tertiary" onClick={() => setActiveActionPopover(item.PipelineItemID)} icon={MenuVerticalIcon} />}
                                                          onClose={() => setActiveActionPopover(null)}
                                                          autofocusTarget="first-node"
                                                        >
                                                          <ActionList
                                                            actionRole="menuitem"
                                                            items={[
                                                              {
                                                                content: 'Edit',
                                                                onAction: () => {
                                                                  setActiveActionPopover(null);
                                                                  openEditItemModal(item);
                                                                }
                                                              },
                                                              {
                                                                content: 'Delete',
                                                                onAction: () => {
                                                                  setActiveActionPopover(null);
                                                                  setItemToDelete(item);
                                                                  setDeleteItemModalOpen(true);
                                                                },
                                                                destructive: true
                                                              }
                                                            ]}
                                                          />
                                                        </Popover>
                                                      )}
                                                    </div>
                                                  </div>
                                                  {item.CompanyName && (
                                                    <div style={{ display: 'flex', alignItems: 'justify-between', gap: '4px' }}>
                                                      <div>
                                                        <Icon source={InventoryIcon} />
                                                      </div>
                                                      <div>{item.CompanyName}</div>
                                                    </div>
                                                  )}
                                                  {item.ContactName && (
                                                    <div style={{ display: 'flex', alignItems: 'justify-between', gap: '4px' }}>
                                                      <div>
                                                        <Icon source={PersonIcon} />
                                                      </div>
                                                      <div>{item.ContactName}</div>
                                                    </div>
                                                  )}
                                                  {item.DueDate && (
                                                    <Text as="p" color="critical">
                                                      Due: {formatDate(item.DueDate)}
                                                    </Text>
                                                  )}
                                                  {/* Add Checklist Section */}
                                                  <div
                                                    style={{
                                                      marginTop: JSON.parse(item.Checklist || '[]').length > 0 ? '4px' : '0',
                                                      borderTop: JSON.parse(item.Checklist || '[]').length > 0 ? '1px solid var(--p-color-border-subdued)' : 'none',
                                                      paddingTop: JSON.parse(item.Checklist || '[]').length > 0 ? '4px' : '0'
                                                    }}
                                                  >
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
                                                      {JSON.parse(item.Checklist || '[]').map((checklistItem, index) => (
                                                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                                          <Checkbox
                                                            checked={checklistItem.completed}
                                                            onChange={() => handleUpdateChecklistItem(item.PipelineItemID, index, { completed: !checklistItem.completed })}
                                                          />
                                                          <div style={{ flex: 1 }}>
                                                            <TextField
                                                              value={checklistItem.text}
                                                              onChange={(value) => {
                                                                const checklist = JSON.parse(item.Checklist || '[]');
                                                                checklist[index].text = value;
                                                                setItems(items.map((i) => (i.PipelineItemID === item.PipelineItemID ? { ...i, Checklist: JSON.stringify(checklist) } : i)));
                                                              }}
                                                              onBlur={() => {
                                                                const checklist = JSON.parse(item.Checklist || '[]');
                                                                handleUpdateChecklistItem(item.PipelineItemID, index, { text: checklist[index].text });
                                                              }}
                                                              autoComplete="off"
                                                              placeholder="Checklist item"
                                                              size="small"
                                                              style={{
                                                                textDecoration: checklistItem.completed ? 'line-through' : 'none',
                                                                color: checklistItem.completed ? 'var(--p-color-text-success)' : undefined
                                                              }}
                                                            />
                                                          </div>
                                                          <Button
                                                            icon={DeleteIcon}
                                                            onClick={() => handleDeleteChecklistItem(item.PipelineItemID, index)}
                                                            tone="critical"
                                                            variant="plain"
                                                            size="micro"
                                                          />
                                                        </div>
                                                      ))}
                                                    </div>
                                                    <div
                                                      className="add-checklist-button"
                                                      style={{
                                                        maxHeight: '0',
                                                        opacity: '0',
                                                        overflow: 'hidden',
                                                        transition: 'all 0.2s ease-in-out'
                                                      }}
                                                    >
                                                      <Button onClick={() => handleAddChecklistItem(item.PipelineItemID)} variant="plain" tone="success" size="slim">
                                                        + Add checklist item
                                                      </Button>
                                                    </div>
                                                  </div>
                                                  {/* Footer with creator info */}
                                                  <div
                                                    style={{
                                                      display: 'flex',
                                                      justifyContent: 'space-between',
                                                      alignItems: 'center',
                                                      marginTop: '4px',
                                                      borderTop: '1px solid var(--p-color-border-subdued)',
                                                      paddingTop: '4px'
                                                    }}
                                                  >
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                                      <Tooltip content={`Created by ${users.find((u) => u.GebruikerID === item.CreatedByID)?.Afkorting || 'Unknown'}`}>
                                                        <div
                                                          style={{
                                                            width: '20px',
                                                            height: '20px',
                                                            borderRadius: '50%',
                                                            backgroundColor: 'var(--p-color-bg-inverse)',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            fontSize: '10px',
                                                            fontWeight: '500',
                                                            color: 'var(--p-color-text-inverse)'
                                                          }}
                                                        >
                                                          {(users.find((u) => u.GebruikerID === item.CreatedByID)?.Afkorting || '?')[0].toUpperCase()}
                                                        </div>
                                                      </Tooltip>
                                                    </div>
                                                  </div>
                                                </BlockStack>
                                              </Card>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                    {provided.placeholder}
                                    {isOwner && (
                                      <div style={{ padding: '8px 0' }}>
                                        <Button
                                          onClick={() => {
                                            setNewItem({ ...newItem, PipelineStageID: stage.PipelineStageID });
                                            setNewItemModalOpen(true);
                                          }}
                                          variant="plain"
                                          textAlign="center"
                                          fullWidth
                                        >
                                          + Add item
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Droppable>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}

                  {/* Add Stage Placeholder Column */}
                  {isOwner && (
                    <div style={{ minWidth: '300px' }}>
                      <Card>
                        <Box padding="400">
                          <Button onClick={() => setNewStageModalOpen(true)} fullWidth size="large" textAlign="center">
                            + Add Stage
                          </Button>
                        </Box>
                      </Card>
                    </div>
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}

      <Modal
        open={newItemModalOpen}
        onClose={() => {
          setNewItemModalOpen(false);
          setNewItem({ Description: '', DueDate: null, PipelineStageID: null, Checklist: JSON.stringify([]) });
          setSelectedCompanyId(null);
          setSelectedContact(null);
          setCompanyContacts([]);
        }}
        title="New Item"
        primaryAction={{
          content: 'Add',
          onAction: handleAddItem,
          disabled: !newItem.Description
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setNewItemModalOpen(false);
              setNewItem({ Description: '', DueDate: null, PipelineStageID: null, Checklist: JSON.stringify([]) });
              setSelectedCompanyId(null);
              setSelectedContact(null);
              setCompanyContacts([]);
            }
          }
        ]}
      >
        <Modal.Section>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (newItem.Description) {
                handleAddItem();
              }
            }}
          >
            <FormLayout>
              <TextField
                label="Description"
                value={newItem.Description}
                onChange={(value) => setNewItem({ ...newItem, Description: value })}
                autoComplete="off"
                multiline={3}
                placeholder="Enter description..."
                required
                autoFocus
              />
              <CompanySelect
                label="Company"
                value={selectedCompanyId}
                onChange={(value) => {
                  setSelectedCompanyId(value);
                  if (value) {
                    // Fetch contacts for the selected company
                    getRequest(`/api/contacts?limit=10000&FIRNR=${value}`)
                      .then((contacts) => {
                        setCompanyContacts(contacts || []);
                        setSelectedContact(null);
                      })
                      .catch((error) => {
                        console.error('Error fetching contacts:', error);
                        setCompanyContacts([]);
                      });
                  } else {
                    setCompanyContacts([]);
                    setSelectedContact(null);
                  }
                }}
              />
              {selectedCompanyId && <ContactReactSelect label="Contact" value={selectedContact} onChange={setSelectedContact} companyId={selectedCompanyId} contacts={companyContacts || []} />}
              <TextField label="Due Date" value={newItem.DueDate || ''} onChange={(value) => setNewItem({ ...newItem, DueDate: value })} type="date" />
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>

      <Modal
        open={newStageModalOpen}
        onClose={() => setNewStageModalOpen(false)}
        title="Add New Stage"
        primaryAction={{
          content: 'Add Stage',
          onAction: handleAddStage,
          disabled: !newStage.Name
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setNewStageModalOpen(false)
          }
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextField label="Name" value={newStage.Name} onChange={(value) => setNewStage({ ...newStage, Name: value })} autoComplete="off" required autoFocus />
            <TextField label="Description" value={newStage.Description} onChange={(value) => setNewStage({ ...newStage, Description: value })} autoComplete="off" multiline={3} />
          </FormLayout>
        </Modal.Section>
      </Modal>

      <Modal
        open={deleteStageModalOpen}
        onClose={() => {
          setDeleteStageModalOpen(false);
          setStageToDelete(null);
        }}
        title="Delete Stage"
        primaryAction={{
          content: 'Delete Stage',
          onAction: handleDeleteStage,
          destructive: true
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setDeleteStageModalOpen(false);
              setStageToDelete(null);
            }
          }
        ]}
      >
        <Modal.Section>
          <BlockStack gap="400">
            <Text>Are you sure you want to delete the stage "{stageToDelete?.Name}"?</Text>
            {items.filter((item) => item.PipelineStageID === stageToDelete?.PipelineStageID).length > 0 && (
              <Banner tone="warning">Warning: This stage contains items that will also be deleted.</Banner>
            )}
          </BlockStack>
        </Modal.Section>
      </Modal>

      <Modal
        open={deleteItemModalOpen}
        onClose={() => {
          setDeleteItemModalOpen(false);
          setItemToDelete(null);
        }}
        title="Delete Item"
      >
        <Modal.Section>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleDeleteItem();
            }}
          >
            <Text>Are you sure you want to delete "{itemToDelete?.Title}"?</Text>
            <button
              type="submit"
              autoFocus
              onClick={(e) => {
                e.preventDefault();
                handleDeleteItem();
              }}
            >
              Delete
            </button>
          </Form>
        </Modal.Section>
      </Modal>

      {/* Edit Item Modal */}
      <Modal
        open={editItemModalOpen}
        onClose={() => {
          setEditItemModalOpen(false);
          setItemToEdit(null);
          setSelectedCompanyId(null);
          setSelectedContact(null);
          setCompanyContacts([]);
        }}
        title="Edit Item"
        primaryAction={{
          content: 'Save Changes',
          onAction: handleEditItem,
          disabled: !itemToEdit?.Description
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setEditItemModalOpen(false);
              setItemToEdit(null);
              setSelectedCompanyId(null);
              setSelectedContact(null);
              setCompanyContacts([]);
            }
          }
        ]}
      >
        <Modal.Section>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (itemToEdit?.Description) {
                handleEditItem();
              }
            }}
          >
            <FormLayout>
              <TextField
                label="Description"
                value={itemToEdit?.Description ?? ''}
                onChange={(value) => {
                  setItemToEdit((prev) => ({
                    ...prev,
                    Description: value
                  }));
                }}
                autoComplete="off"
                multiline={3}
                placeholder="Enter description..."
                required
                autoFocus
              />
              <CompanySelect
                label="Company"
                value={selectedCompanyId}
                onChange={(value) => {
                  setSelectedCompanyId(value);
                  if (value) {
                    // Fetch contacts for the selected company
                    getRequest(`/api/contacts?limit=10000&FIRNR=${value}`)
                      .then((contacts) => {
                        setCompanyContacts(contacts || []);
                        setSelectedContact(null);
                      })
                      .catch((error) => {
                        console.error('Error fetching contacts:', error);
                        setCompanyContacts([]);
                      });
                  } else {
                    setCompanyContacts([]);
                    setSelectedContact(null);
                  }
                }}
              />
              {selectedCompanyId && <ContactReactSelect label="Contact" value={selectedContact} onChange={setSelectedContact} companyId={selectedCompanyId} contacts={companyContacts || []} />}
              <TextField label="Due Date" value={itemToEdit?.DueDate || ''} onChange={(value) => setItemToEdit({ ...itemToEdit, DueDate: value })} type="date" />

              {/* Add Checklist Section */}
              <div style={{ marginTop: '16px', borderTop: '1px solid var(--p-color-border-subdued)', paddingTop: '16px' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                  {JSON.parse(itemToEdit?.Checklist || '[]').map((checklistItem, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <Checkbox
                        checked={checklistItem.completed}
                        onChange={() => {
                          const checklist = JSON.parse(itemToEdit.Checklist || '[]');
                          checklist[index].completed = !checklist[index].completed;
                          setItemToEdit({ ...itemToEdit, Checklist: JSON.stringify(checklist) });
                          handleUpdateChecklistItem(itemToEdit.PipelineItemID, index, { completed: !checklistItem.completed });
                        }}
                      />
                      <div style={{ flex: 1 }}>
                        <TextField
                          value={checklistItem.text}
                          onChange={(value) => {
                            const checklist = JSON.parse(itemToEdit.Checklist || '[]');
                            checklist[index].text = value;
                            setItemToEdit({ ...itemToEdit, Checklist: JSON.stringify(checklist) });
                          }}
                          onBlur={() => {
                            const checklist = JSON.parse(itemToEdit.Checklist || '[]');
                            handleUpdateChecklistItem(itemToEdit.PipelineItemID, index, { text: checklist[index].text });
                          }}
                          autoComplete="off"
                          placeholder="Checklist item"
                          size="small"
                          style={{
                            textDecoration: checklistItem.completed ? 'line-through' : 'none',
                            color: checklistItem.completed ? 'var(--p-color-text-success)' : undefined
                          }}
                        />
                      </div>
                      <Button
                        icon={DeleteIcon}
                        onClick={() => {
                          handleDeleteChecklistItem(itemToEdit.PipelineItemID, index);
                          const checklist = JSON.parse(itemToEdit.Checklist || '[]');
                          checklist.splice(index, 1);
                          setItemToEdit({ ...itemToEdit, Checklist: JSON.stringify(checklist) });
                        }}
                        tone="critical"
                        variant="plain"
                        size="micro"
                      />
                    </div>
                  ))}
                </div>
                <div style={{ marginTop: '4px' }}>
                  <Button
                    onClick={() => {
                      const checklist = JSON.parse(itemToEdit?.Checklist || '[]');
                      checklist.push({ text: '', completed: false });
                      setItemToEdit({ ...itemToEdit, Checklist: JSON.stringify(checklist) });
                      handleAddChecklistItem(itemToEdit.PipelineItemID);
                    }}
                    variant="plain"
                    tone="success"
                    size="slim"
                  >
                    + Add checklist item
                  </Button>
                </div>
              </div>
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>

      <Modal
        open={editStageModalOpen}
        onClose={() => {
          setEditStageModalOpen(false);
          setStageToEdit(null);
        }}
        title="Edit Stage"
        primaryAction={{
          content: 'Save Changes',
          onAction: handleEditStage,
          disabled: !stageToEdit?.Name
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => {
              setEditStageModalOpen(false);
              setStageToEdit(null);
            }
          }
        ]}
      >
        <Modal.Section>
          <FormLayout>
            <TextField label="Name" value={stageToEdit?.Name || ''} onChange={(value) => setStageToEdit({ ...stageToEdit, Name: value })} autoComplete="off" required autoFocus />
            <TextField label="Description" value={stageToEdit?.Description || ''} onChange={(value) => setStageToEdit({ ...stageToEdit, Description: value })} autoComplete="off" multiline={3} />
          </FormLayout>
        </Modal.Section>
      </Modal>
    </Page>
  );
}
