import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, Filters, DataTable, Page, Card, Link, Button, Tooltip } from '@shopify/polaris';
import { formatToCurrency, getTableFooter, truncate, getDocPdfLinkForListViewIncoming, fetchTableData, getRequest, filtersToApiUrl, getUserOrg } from '../../shared/components/functions.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../shared/components/listFilter.jsx';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { CreditCardIcon, CreditCardPercentIcon } from '@shopify/polaris-icons';
import CreditnoteInCollection from './components/creditnoteInCollection';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';
import { ApprovalBadges } from '../../shared/components/badges/approvalBadges.jsx';

export default function CreditnotesInPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const organisations = useLookupStore((state) => state.organisations);
  const users = useLookupStore((state) => state.users);
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [invoiceCollectionModalOpen, setInvoiceCollectionModalOpen] = useState(false);
  const [collectionInvoice, setCollectionIvoice] = useState({});
  const [fullInvoiceCollection, setFullInvoiceCollection] = useState(false); //full if fully paid
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    supplier: { type: 'text', name: 'Supplier', value: url.get('supplier') || '' },
    dateIn: { type: 'date', name: 'Date in', value: url.get('dateIn') || '' },
    dateExpire: { type: 'date', name: 'Date expire', value: url.get('dateExpire') || '' },
    dateInvoice: { type: 'date', name: 'Date invoice', value: url.get('dateInvoice') || '' },
    reference: { type: 'text', name: 'Reference', value: url.get('reference') || '' },
    valueInvoice: { type: 'range', name: 'Value invoice', value: url.get('valueInvoice') ? JSON.parse(url.get('valueInvoice')) : null, rangeBottom: 0, rangeTop: 1000000, prefix: '€' },
    valueOpen: { type: 'range', name: 'Value open', value: url.get('valueOpen') ? JSON.parse(url.get('valueOpen')) : null, rangeBottom: 0, rangeTop: 1000000, prefix: '€' },
    datePayment: { type: 'date', name: 'Date payment', value: url.get('datePayment') || '' },
    remarks: { type: 'text', name: 'Remarks', value: url.get('remarks') || '' },
    paid: { type: 'status', name: 'Status paid', value: url.get('status') || 'all' },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/creditnotes_in', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  async function fetchCreditnotes() {
    const data = await getRequest(`/api/creditnotes_in?page=${page}&limit=100&${filtersToApiUrl(filters)}`);
    setItems(data);
  }

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const date_payment = item.status ? String(item.datumbetalingout).substring(0, 10) : null;
        const status = item.status ? <Badge>Paid</Badge> : <Badge tone="warning">Not Paid</Badge>;
        const displayButtons = item.S3ObjectKeys && item.S3ObjectKeys.length > 0 ? item.processed && !item.status : !item.status;
        return [
          <div style={{ display: 'flex', gap: '5px' }}>
            {!item.processed && item.S3ObjectKeys ? '🔔' : null}
            <Link removeUnderline url={'/creditnotes_in/' + item.CreditnotaINvolgnummer}>
              {item.CreditnotaInNummer}
            </Link>
          </div>,
          getDocPdfLinkForListViewIncoming(item, item.supplier_number, item.CreditnotaInNummer, user, 'Creditnota In', 'KI'),
          <Link removeUnderline url={'/companies/' + item.LeverancierNummer}>
            {truncate(item.supplier_name, 30)}
          </Link>,
          String(item.datumin).substring(0, 10),
          String(item.datumbetaling).substring(0, 10),
          String(item.datum).substring(0, 10),
          item.faktuurref,
          formatToCurrency(item.Bedragfaktuur),
          formatToCurrency(item.bedragopen),
          date_payment,
          truncate(item.memo, 20),
          <ApprovalBadges approvals={JSON.parse(item.approvals)} resource="creditnote" />,
          item.Afkorting ? <Badge>{item.Afkorting}</Badge> : '',
          status,
          displayButtons ? (
            <div style={{ margin: '-4px 0' }}>
              <Tooltip content="Collect full payment">
                <Button
                  size="slim"
                  icon={CreditCardIcon}
                  onClick={() => {
                    setInvoiceCollectionModalOpen(true);
                    setCollectionIvoice(item);
                    setFullInvoiceCollection(true);
                  }}
                />
              </Tooltip>
              <Tooltip content="Collect partial payment">
                <Button
                  size="slim"
                  icon={CreditCardPercentIcon}
                  onClick={() => {
                    setInvoiceCollectionModalOpen(true);
                    setCollectionIvoice(item);
                    setFullInvoiceCollection(false);
                  }}
                />
              </Tooltip>
            </div>
          ) : (
            ''
          )
        ];
      })
    : [];

  const autoProcessEmails = {
    schuilenburg: ' creditnotes@admin.schuilenburg.com ',
    aha: 'creditnotes-aha@admin.schuilenburg.com',
    jadibo: 'creditnotes-jadibo@admin.schuilenburg.com',
    adriaens: 'creditnotes-adriaens@admin.schuilenburg.com',
    localhost: 'creditnotes-localhost@admin.schuilenburg.com' // for testing
  };

  const getAutoProcessEmail = () => {
    const url = window.location.href;
    const keys = Object.keys(autoProcessEmails);
    for (let i = 0; i < keys.length; i++) {
      if (url.includes(keys[i])) return autoProcessEmails[keys[i]];
    }
    return '';
  };

  const totals =
    items.length > 0
      ? ['', '', '', '', '', '', '', formatToCurrency(items.reduce((p, c) => p + c.Bedragfaktuur, 0)), formatToCurrency(items.reduce((p, c) => p + c.bedragopen, 0)), '', '', '', '', '', '']
      : null;

  return (
    <Page title="Credit notes IN" subtitle={`Upload automatically by emailing to ${getAutoProcessEmail()}`} fullWidth primaryAction={{ content: 'New creditnote IN', url: '/creditnotes_in/new' }}>
      <CreditnoteInCollection
        modalOpen={invoiceCollectionModalOpen}
        setModalOpen={setInvoiceCollectionModalOpen}
        collectionInvoice={collectionInvoice}
        fetchData={fetchCreditnotes}
        fullInvoiceCollection={fullInvoiceCollection}
      />
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter credit notes on number, customer name, total value, value open and reference"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          verticalAlign="middle"
          stickyHeader
          increasedTableDensity
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'numeric', 'numeric', 'text', 'text', 'text', 'text', 'text', 'numeric']}
          headings={[
            <b>Number</b>,
            <b>PDF</b>,
            <b>Supplier</b>,
            <b>Date in</b>,
            <b>Date expire</b>,
            <b>Date invoice</b>,
            <b>Reference</b>,
            <b>Value invoice</b>,
            <b>Value open</b>,
            <b>Date payment</b>,
            <b>Remarks</b>,
            <b>Approvals</b>,
            <b>Owner</b>,
            <b>Status</b>,
            <b>Payment</b>
          ]}
          totals={totals}
          showTotalsInFooter
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'incoming creditnotes')}
        />
      </Card>
    </Page>
  );
}
