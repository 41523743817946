import invariant from 'invariant';
import { without } from 'lodash';
import { object, string, date, number, boolean, ValidationError, addMethod } from 'yup';
import { formatDate, getCurrenctDatetimeYYYMMDD } from './components/functions';

const taxIdValidation = {
  AT: '(AT)?U[0-9]{8}',
  BE: '(BE)?[0-1][0-9]{9}',
  BG: '(BG)?[0-9]{9,10}',
  CY: '(CY)?[0-9]{8}[A-Z]',
  CZ: '(CZ)?[0-9]{8,10}',
  DE: '(DE)?[0-9]{9}',
  DK: '(DK)?[0-9]{8}',
  EE: '(EE)?[0-9]{9}',
  GR: '(EL|GR)?[0-9]{9}',
  ES: '(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]',
  FI: '(FI)?[0-9]{8}',
  FR: '(FR)?[0-9A-Z]{2}[0-9]{9}',
  HU: '(HU)?[0-9]{8}',
  HR: '(HR)?{11}',
  IE: '(IE)?.*',
  IT: '(IT)?[0-9]{11}',
  LT: '(LT)?([0-9]{9}|[0-9]{12})',
  LU: '(LU)?[0-9]{8}',
  LV: '(LV)?[0-9]{11}',
  MT: '(MT)?[0-9]{8}',
  NL: '(NL)?[0-9]{9}B[0-9]{2}',
  PL: '(PL)?[0-9]{10}',
  PT: '(PT)?[0-9]{9}',
  RO: '(RO)?[0-9]{2,10}',
  SE: '(SE)?[0-9]{12}',
  SI: '(SI)?[0-9]{8}',
  SK: '(SK)?[0-9]{10}'
};

export const getSchema = (type, url, TAV, user, isEU) => {
  addMethod(object, 'atLeastOneRequired', function atLeastOneRequired(list, message) {
    invariant(
      list.every((field) => this.fields[field]),
      'All required fields should be defined before calling atLeastOneRequired'
    );
    return this.shape(
      list.reduce(
        (acc, field) => ({
          ...acc,
          [field]: this.fields[field].when(without(list, field), {
            is: (...values) => !values.some((item) => item),
            then: this.fields[field].required(message)
          })
        }),
        {}
      ),
      list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map((i) => [item, i])], [])
    );
  });

  addMethod(string, 'testDate', function testDate(field) {
    return this.test('validate-date', function (value) {
      if (value === undefined || value === null) return true;

      const dateFormatRegex = /^\d{4}-\d{2}-\d{2}/;
      if (!dateFormatRegex.test(value)) return this.createError({ message: `${field}: Invalid date format` });

      // Extract the year part from the date string
      const year = parseInt(value.substring(0, 4), 10);

      // Check if the year is a four-digit number within a valid range
      if (isNaN(year) || year < 1970 || year > 2999) return this.createError({ message: `${field}: Invalid year` });

      return true;
    });
  });

  addMethod(object, 'updateToPaid', function updateToPaid() {
    return this.test('check-values', function (value) {
      const incasseren = value.Incasseren; // string
      const openValue = value.bedragopen; // 0 or null
      const paidDate = value.datumbetalingout; // string
      const isPaid = value.status; // true

      if (incasseren || !openValue || paidDate || isPaid) {
        let errors = [];
        !incasseren ? errors.push('Select incasseren') : null;
        openValue ? errors.push('Set open amount to 0') : null;
        !paidDate ? errors.push('Select a payment date') : null;
        !isPaid ? errors.push('Mark invoice as paid') : null;

        if (errors.length > 0) {
          return this.createError({
            message: `Is this invoice paid?\nThis form contains conflicting information. In order to save the invoice as paid, please check the following fields:\n${errors.map((e) => e).join('\n')}`
          });
        }
      }
      return true;
    });
  });

  addMethod(string, 'testZip', function testZip(field) {
    return this.test('validate-zip', function (value) {
      if (value === undefined || value === null) return true;

      const stateZipCodeRegex = /^[A-Z]{2}\s\d{5}(?:-\d{4})?$/;
      if (!stateZipCodeRegex.test(value)) return this.createError({ message: `${field}: Invalid ZIP format` });

      return true;
    });
  });

  switch (type) {
    case 'company':
      return object({
        NAAM: string().default(undefined).required('The name of the company is required'),
        STRAAT_NR: string().default(undefined).nullable(),
        WOONPL: string().default(undefined).nullable(),
        POSTNR: string()
          .default(undefined)
          .nullable()
          .when('LandID', {
            is: 468,
            then: string().testZip('ZIP')
          }),
        TEL: string().default(undefined).nullable(),
        FAX: string().default(undefined).nullable(),
        TYPE: string().default(undefined).nullable(),
        OPMERKING: string().default(undefined).nullable(),
        GEBIED: string().default(undefined).nullable(),
        BTW: string()
          .default(undefined)
          .nullable()
          .when('BTWPlichtig', {
            is: true,
            then: string()
              .default(undefined)
              .typeError('The VAT number is required')
              .test('tax validation', 'The VAT number has the wrong format', function (value) {
                const country = value.substring(0, 2).toUpperCase();
                const vatFormat = taxIdValidation[country];
                return isEU ? (vatFormat ? value.match(vatFormat) : false) : true;
              })
              .min(7, 'The VAT number must be 7 characters long')
              .required('The VAT number is required')
          }),
        FIRNR: string().default(undefined).nullable(),
        FIRNROLD: string().default(undefined).nullable(),
        DISCONTINUED: boolean().default(false),
        personeelsbestand: number().default(undefined).nullable(),
        'E-mail': string().default(undefined).nullable(),
        select: boolean().default(false).nullable(),
        'web-site': string().default(undefined).nullable(),
        Land: string().default(undefined).nullable(),
        Voertaal: string().default(undefined).nullable(),
        Klant: boolean().default(true),
        Leverancier: boolean().default(false),
        'Betalingsvw aankoop': string().default(undefined).nullable(),
        Artikel: string().default(undefined).nullable(),
        Status: string().default(undefined).nullable(),
        'Last modif': date().default(getCurrenctDatetimeYYYMMDD()).nullable(),
        betalingID: number()
          .default(undefined)
          .nullable()
          .when('Klant', {
            is: true,
            then: number().default(undefined).typeError('The payment condition is required for the customer').required('The payment condition is required for the customer')
          }),
        http: string().default(undefined).nullable(),
        Kontrakt: boolean().default(false),
        s_GUID: string().default(undefined).nullable(),
        Opmerkingen1: string().default(undefined).nullable(),
        Opmerkingen2: string().default(undefined).nullable(),
        Gesperd: boolean().default(false),
        FirNrFactuur: number().default(undefined).nullable(),
        LevBetalingID: number()
          .default(undefined)
          .nullable()
          .when('Leverancier', {
            is: true,
            then: number().default(undefined).typeError('The payment condition is required for the supplier').required('The payment condition is required for the supplier')
          }),
        LandID: number()
          .default(window.location.href.includes('perfectmoose') ? 400 : 300)
          .required('The country is required'),
        TaalID: number().default(undefined).required('The language is required'),
        Klantnummer: string().default(undefined),
        PostcodeID: number().default(undefined).nullable(),
        BTWFormatID: number().default(undefined).nullable(),
        GSM: string().default(undefined).nullable(),
        GSMFormatID: number().default(undefined).nullable(),
        TELFormatID: number().default(undefined).nullable(),
        FaxFormatID: number().default(undefined).nullable(),
        SectorID: number().default(undefined).nullable(),
        Particulier: boolean().default(false),
        BTWPlichtig: boolean().default(false),
        POSTNRFormatID: number().default(undefined).nullable(),
        KlantStatusID: number().default(1).required('The customer status is required'),
        PartBetalingID: number()
          .default(undefined)
          .nullable()
          .when('Particulier', {
            is: true,
            then: number().default(undefined).typeError('The payment condition is required for the particulier').required('The payment condition is required for the particulier')
          }),
        StatusReden: string().default(undefined).nullable(),
        scoreInfo: string().default(undefined).nullable(),
        scoreInt: number().default(undefined).nullable(),
        creditLimit: number().default(undefined).nullable(),
        creditCode: number().default(undefined).nullable(),
        closingDate: string().default(undefined).nullable(),
        instagramHandle: string().default(undefined).nullable(),
        twitterHandle: string().default(undefined).nullable(),
        facebookHandle: string().default(undefined).nullable(),
        linkedinHandle: string().default(undefined).nullable(),
        youtubeHandle: string().default(undefined).nullable(),
        tiktokHandle: string().default(undefined).nullable()
      }).test('Select relation', null, (obj) => {
        if (obj.Klant || obj.Leverancier || obj.Particulier) {
          return true; // everything is fine
        }
        return new ValidationError('At least one relation (customer, supplier or private entity) is required', null, 'relations');
      });

    case 'contact':
      return object({
        NAAM: string().default(undefined).required('Name is required'),
        TITEL: string()
          .default(url.get('TITEL') || undefined)
          .nullable('Title is required'),
        STATUS: string()
          .default(url.get('STATUS') || undefined)
          .nullable(),
        TELNR: string()
          .default(url.get('TELNR') || undefined)
          .nullable(),
        OPMERKING: string()
          .default(url.get('OPMERKING') || undefined)
          .nullable(),
        TAAL: string()
          .default(url.get('TAAL') || undefined)
          .nullable(),
        DEPT: string()
          .default(url.get('DEPT') || undefined)
          .nullable(),
        PUB: string()
          .default(url.get('PUB') || undefined)
          .nullable(),
        CONNR: string().default(url.get('CONNR') || undefined),
        KLANR: string()
          .default(url.get('KLANR') || undefined)
          .required('The customer number is required'),
        KLANROLD: string()
          .default(url.get('KLANROLD') || undefined)
          .nullable(),
        DISCONTINUED: boolean().default(url.get('DISCONTINUED') || false),
        'Last modif': date()
          .default(url.get('Last modif') || undefined)
          .nullable(),
        'E-mail': string()
          .default(url.get('E-mail') || undefined)
          .nullable(),
        s_GUID: string()
          .default(url.get('s_GUID') || undefined)
          .nullable(),
        Factuur: boolean().default(url.get('Factuur') || false),
        TaalID: number()
          .default(url.get('TaalID') || undefined)
          .required('Language is required'),
        AanspreektitelID: number()
          .default(url.get('AanspreektitelID') || undefined)
          .nullable(),
        TELFormatID: number()
          .default(url.get('TELFormatID') || undefined)
          .nullable(),
        TEL: string()
          .default(url.get('TEL') || undefined)
          .nullable(),
        GSM: string()
          .default(url.get('GSM') || undefined)
          .nullable(),
        GSMFormatID: number()
          .default(url.get('GSMFormatID') || undefined)
          .nullable(),
        FAXFormatID: number()
          .default(url.get('FAXFormatID') || undefined)
          .nullable(),
        FAX: string()
          .default(url.get('FAX') || undefined)
          .nullable(),
        SendMail: boolean().default(url.get('SendMail') || false),
        Montagebon: boolean().default(url.get('Montagebon') || false)
      }).atLeastOneRequired(['GSM', 'E-mail', 'TEL', 'FAX'], 'At least one contact method (TEL, GSM, Email or FAX) is required');

    case 'quotation':
      return object({
        offertenr: number().default(undefined),
        klantnaam: string()
          .default(url.get('klantnaam') || undefined)
          .nullable(),
        klantnummer: string()
          .default(url.get('klantnummer') || undefined)
          .required('Customer is required'),
        adres: string()
          .default(url.get('adres') || undefined)
          .nullable(),
        postkode: string()
          .default(url.get('postkode') || undefined)
          .nullable(),
        woonplaats: string()
          .default(url.get('woonplaats') || undefined)
          .nullable(),
        betaling: string()
          .default(url.get('betaling') || undefined)
          .nullable(),
        taal: string()
          .default(url.get('taal') || undefined)
          .nullable(),
        btwnummer: string()
          .default(url.get('btwnummer') || undefined)
          .nullable(),
        datum: string().default(getCurrenctDatetimeYYYMMDD()).required('Date is required').testDate('Date'),
        refklant: string()
          .default(url.get('refklant') || undefined)
          .nullable(),
        refklantdatum: string()
          .default(url.get('refklantdatum') || undefined)
          .nullable()
          .testDate('Customer date'),
        tav: string()
          .default(url.get('tav') || undefined)
          .nullable(),
        status: string()
          .default(url.get('status') || undefined)
          .nullable(),
        munt: string()
          .default(url.get('munt') || undefined)
          .nullable(),
        land: string()
          .default(url.get('land') || undefined)
          .nullable(),
        betalingID: number()
          .default(url.get('betalingID') || undefined)
          .nullable(),
        leveringstermijn: string()
          .default(url.get('leveringstermijn') || undefined)
          .nullable(),
        machinenunner: string()
          .default(url.get('machinenunner') || undefined)
          .nullable(),
        machinetype: string()
          .default(url.get('machinetype') || undefined)
          .nullable(),
        Angebot: string()
          .default(url.get('Angebot') || undefined)
          .nullable(),
        Faxnummer: string()
          .default(url.get('Faxnummer') || undefined)
          .nullable(),
        Offertenummer: string().default(url.get('Offertenummer') || undefined),
        LandID: number()
          .default(url.get('LandID') || undefined)
          .nullable(),
        TaalID: number()
          .default(url.get('TaalID') || undefined)
          .required('Language is required'),
        MachineTypeID: number()
          .default(url.get('MachineTypeID') || undefined)
          .nullable(),
        OfferteStatusID: number()
          .default(url.get('OfferteStatusID') || 0)
          .required('Status is required'),
        VervangingsOfferteID: number()
          .default(url.get('VervangingsOfferteID') || undefined)
          .nullable(),
        BestellingID: number()
          .default(url.get('BestellingID') || undefined)
          .nullable(),
        Verstuurd: boolean().default(url.get('Verstuurd') || false),
        Ontvangen: boolean().default(url.get('Ontvangen') || false),
        ProjectID: number()
          .default(url.get('ProjectID') || undefined)
          .nullable(),
        LeverancierID: number()
          .default(url.get('LeverancierID') || undefined)
          .nullable(),
        OrderbevestigingID: number()
          .default(url.get('OrderbevestigingID') || undefined)
          .nullable(),
        DocumentPrijzen: string()
          .default(url.get('DocumentPrijzen') || undefined)
          .nullable(),
        GebruikerID: number()
          .default(url.get('GebruikerID') || user.id)
          .nullable()
      });

    case 'inquiry':
      return object({
        PrijsaanvraagID: number().default(undefined),
        LeverancierID: number()
          .default(url.get('LeverancierID') || undefined)
          .required('Supplier is required'),
        Datum: string().default(getCurrenctDatetimeYYYMMDD()).required('Date is required').testDate('Date'),
        RefKlant: string()
          .default(url.get('RefKlant') || undefined)
          .max(100, 'Reference customer must be 100 characters or less')
          .nullable(),
        RefKlantDatum: string()
          .default(url.get('RefKlantDatum') || undefined)
          .nullable()
          .testDate('Customer date'),
        Prijsaanvraagnummer: string().default(url.get('Prijsaanvraagnummer') || undefined),
        TaalID: number()
          .default(url.get('TaalID') || undefined)
          .required('Language is required'),
        Verstuurd: boolean().default(url.get('Verstuurd') || false),
        Ontvangen: boolean().default(url.get('Ontvangen') || false),
        KlantID: number()
          .default(url.get('KlantID') || undefined)
          .nullable(),
        MachineTypeID: number()
          .default(url.get('MachineTypeID') || undefined)
          .nullable(),
        MachineNummer: string()
          .default(url.get('MachineNummer') || undefined)
          .max(50, 'Machine number must be 50 characters or less')
          .nullable(),
        Leveringstermijn: string()
          .default(url.get('Leveringstermijn') || undefined)
          .max(50, 'Delivery term must be 50 characters or less')
          .nullable(),
        ProjectID: number()
          .default(url.get('ProjectID') || undefined)
          .nullable(),
        MachineType: string()
          .default(url.get('MachineType') || undefined)
          .max(50, 'Machine type must be 50 characters or less')
          .nullable(),
        GebruikerID: number().default(user.id).nullable()
      });

    case 'sale':
      return object({
        OrderbevestigingID: string().default(undefined),
        LeverancierID: number()
          .default(url.get('LeverancierID') || undefined)
          .nullable(),
        Datum: string().default(getCurrenctDatetimeYYYMMDD()).required('Date is required').testDate('Date'),
        RefKlant: string()
          .default(url.get('RefKlant') || undefined)
          .nullable(),
        RefKlantDatum: string()
          .default(url.get('RefKlantDatum') || undefined)
          .nullable()
          .testDate('Date customer'),
        Orderbevestigingnummer: string().default(url.get('Orderbevestigingnummer') || undefined),
        TaalID: number()
          .default(url.get('TaalID') || 1)
          .required('Language is required'),
        Verstuurd: boolean().default(url.get('Verstuurd') || false),
        KlantID: number()
          .default(url.get('KlantID') || undefined)
          .required('Customer is required'),
        MachineTypeID: number()
          .default(url.get('MachineTypeID') || undefined)
          .nullable(),
        MachineNummer: string()
          .default(url.get('MachineNummer') || undefined)
          .nullable(),
        Leveringstermijn: string()
          .default(url.get('Leveringstermijn') || undefined)
          .nullable(),
        ProjectID: number()
          .default(url.get('ProjectID') || undefined)
          .nullable(),
        MachineType: string()
          .default(url.get('MachineType') || undefined)
          .nullable(),
        Aanbod: string()
          .default(url.get('Aanbod') || undefined)
          .nullable(),
        FacturatieKlantID: number()
          .default(url.get('FacturatieKlantID') || undefined)
          .nullable(),
        LeveringKlantID: number()
          .default(url.get('LeveringKlantID') || undefined)
          .nullable(),
        GebruikerID: number().default(user.id).required('User is required'),
        Notities: string()
          .default(url.get('Notities') || undefined)
          .nullable(),
        KlantContactID: number()
          .default(url.get('KlantContactID') || undefined)
          .nullable(),
        SendCloudID: number()
          .default(url.get('SendCloudID') || undefined)
          .nullable(),
        Canceled: boolean().default(url.get('Canceled') || false),
        Closed: boolean().default(url.get('Closed') || false),
        InvoicedAha: boolean().default(url.get('InvoicedAha') || false),
        FulfillerUserID: number()
          .default(url.get('FulfillerUserID') || undefined)
          .nullable(),
        DocumentPrijzen: string()
          .default(url.get('DocumentPrijzen') || undefined)
          .nullable(),
        OfferteID: number()
          .default(url.get('OfferteID') || undefined)
          .nullable()
      });

    case 'purchase':
      return object({
        BestellingID: number().nullable(),
        LeverancierID: number()
          .default(url.get('LeverancierID') || undefined)
          .required('Supplier is required'),
        Datum: string().default(getCurrenctDatetimeYYYMMDD()).required('Date is required').testDate('Date'),
        RefKlant: string()
          .default(url.get('RefKlant') || undefined)
          .nullable(),
        RefKlantDatum: string()
          .default(url.get('RefKlantDatum') || undefined)
          .nullable()
          .testDate('Customer date'),
        Bestellingnummer: string().default(url.get('Bestellingnummer') || undefined),
        TaalID: number()
          .default(url.get('TaalID') || undefined)
          .required('Language is required'),
        Verstuurd: boolean().default(url.get('Verstuurd') || false),
        Ontvangen: boolean().default(url.get('Ontvangen') || false),
        KlantID: number()
          .default(url.get('KlantID') || undefined)
          .required('Customer is required'),
        MachineTypeID: number()
          .default(url.get('MachineTypeID') || undefined)
          .nullable(),
        MachineNummer: string()
          .default(url.get('MachineNummer') || undefined)
          .nullable(),
        Leveringstermijn: string()
          .default(url.get('Leveringstermijn') || undefined)
          .nullable(),
        ProjectID: number()
          .default(url.get('ProjectID') || undefined)
          .nullable(),
        MachineType: string()
          .default(url.get('MachineType') || undefined)
          .nullable(),
        Aanbod: string()
          .default(url.get('Aanbod') || undefined)
          .nullable(),
        BestellingTypeID: number()
          .default(url.get('BestellingTypeID') || 1)
          .required('Purchase type is required'),
        Geannuleerd: boolean().default(url.get('Geannuleerd') || false),
        GebruikerID: number().default(user.id).nullable()
      });

    case 'delivery':
      return object({
        Bonnr: number().nullable(),
        klantnaam: string()
          .default(url.get('klantnaam') || undefined)
          .max(255, 'Customer name must be 255 characters or less')
          .required('Customer is required'),
        klantnummer: number()
          .default(url.get('klantnummer') || undefined)
          .required('Customer number is required'),
        adres: string()
          .default(url.get('adres') || undefined)
          .required('Address is required'),
        postkode: string()
          .default(url.get('postkode') || undefined)
          .max(20, 'ZIP code must be 20 characters or less')
          .required('ZIP code is required'),
        woonplaats: string()
          .default(url.get('woonplaats') || undefined)
          .max(50, 'City must be 50 characters or less')
          .required('City is required'),
        taal: string()
          .default(url.get('taal') || undefined)
          .nullable(),
        tav: string().default(undefined).max(50, 'Tav must be 50 characters or less').nullable(),
        datum: string().default(getCurrenctDatetimeYYYMMDD()).required('Date is required').testDate('Date'),
        refklant: string()
          .default(url.get('refklant') || undefined)
          .max(100, 'Reference customer must be 100 characters or less')
          .nullable(),
        refklantdatum: string()
          .default(url.get('refklantdatum') || undefined)
          .nullable()
          .testDate('Customer date'),
        '#kopie': string()
          .default(url.get('#kopie') || undefined)
          .nullable(),
        btwnummer: string()
          .default(url.get('btwnummer') || undefined)
          .nullable(),
        gefaktureerd: boolean()
          .default(url.get('gefaktureerd') || false)
          .nullable(),
        Land: string()
          .default(url.get('Land') || undefined)
          .nullable(),
        LeveringsbonNummer: string().default(url.get('LeveringsbonNummer') || undefined),
        TaalID: number()
          .default(url.get('TaalID') || undefined)
          .required('Language is required'),
        LandID: number()
          .default(url.get('LandID') || undefined)
          .required('Country is required'),
        Verstuurd: boolean()
          .default(url.get('Verstuurd') || false)
          .nullable(),
        OrderbevestigingID: number()
          .default(url.get('OrderbevestigingID') || undefined)
          .nullable(),
        KgBruto: number()
          .default(url.get('KgBruto') || undefined)
          .nullable(),
        Notities: string()
          .default(url.get('Notities') || undefined)
          .nullable(),
        GebruikerID: number().default(user.id).nullable()
      });

    case 'invoice':
      return object({
        faktuurnr: string().default(undefined),
        klantnaam: string()
          .default(url.get('klantnaam') || undefined)
          .nullable(),
        klantnummer: string()
          .default(url.get('klantnummer') || undefined)
          .required('Customer is required'),
        adres: string()
          .default(url.get('adres') || undefined)
          .nullable(),
        postkode: string()
          .default(url.get('postkode') || undefined)
          .nullable(),
        woonplaats: string()
          .default(url.get('woonplaats') || undefined)
          .nullable(),
        betaling: string()
          .default(url.get('betaling') || undefined)
          .nullable(),
        taal: string()
          .default(url.get('taal') || undefined)
          .nullable(),
        btwnummer: string()
          .default(url.get('btwnummer') || undefined)
          .nullable(),
        datum: string()
          .default(url.get('datum') || getCurrenctDatetimeYYYMMDD())
          .required('Date is required')
          .testDate('Date'),
        refklant: string()
          .default(url.get('refklant') || undefined)
          .nullable(),
        refklantdatum: string()
          .default(url.get('refklantdatum') || getCurrenctDatetimeYYYMMDD())
          .nullable()
          .testDate('Customer date'),
        '#kopie': string()
          .default(url.get('#kopie') || undefined)
          .nullable(),
        '%handelskorting': string()
          .default(url.get('%handelskorting') || undefined)
          .nullable(),
        '%financkorting': string()
          .default(url.get('%financkorting') || undefined)
          .nullable(),
        '%gefaktureerd': string()
          .default(url.get('%gefaktureerd') || undefined)
          .nullable(),
        referentiefaktuurnummer: string()
          .default(url.get('referentiefaktuurnummer') || undefined)
          .nullable(),
        tav: string()
          .default(TAV[url.get('TaalID')] || undefined)
          .nullable(),
        status: string()
          .default(url.get('status') || 0)
          .required('Status is required'),
        munt: string()
          .default(url.get('munt') || undefined)
          .nullable(),
        bedragopen: number()
          .default(url.get('bedragopen') || 0)
          .nullable(),
        datumbetaling: string()
          .default(url.get('datumbetaling') || undefined)
          .nullable()
          .testDate('Payment date'),
        land: string()
          .default(url.get('land') || undefined)
          .nullable(),
        totaalbedrag: number()
          .default(url.get('totaalbedrag') || 0)
          .min(1, 'Total amount must be greater than 1, add extra lines in the content of the invoice')
          .nullable(),
        betalingID: string()
          .default(url.get('betalingID') || undefined)
          .required('Payment condition is required'),
        laatsteaanmaning: string()
          .default(url.get('laatsteaanmaning') || getCurrenctDatetimeYYYMMDD())
          .nullable()
          .testDate('Last reminder'),
        memo: string()
          .default(url.get('memo') || undefined)
          .nullable(),
        InWe: string()
          .default(url.get('InWe') || false)
          .nullable(),
        Incasseren: string()
          .default(url.get('Incasseren') || undefined)
          .nullable()
          .when('status', {
            is: '9',
            then: string().typeError('Incasseren is required').required('Incasseren is required')
          }),
        Factuurnummer: string()
          .default(url.get('Factuurnummer') || undefined)
          .nullable(),
        Link: string()
          .default(url.get('Link') || undefined)
          .nullable(),
        Vervaldatum: string()
          .default(url.get('Vervaldatum') || undefined)
          .required('Expiry date is required')
          .testDate('Expiry date'),
        TaalID: string()
          .default(url.get('TaalID') || undefined)
          .required('Language is required'),
        LandID: string()
          .default(url.get('LandID') || undefined)
          .required('Country is required'),
        BTWVerlegdID: number()
          .default(url.get('BTWVerlegdID') || undefined)
          .nullable(),
        Verstuurd: boolean()
          .default(url.get('Verstuurd') || false)
          .nullable(),
        Vervallen: boolean()
          .default(url.get('Vervallen') || false)
          .required('The expiry date is required'),
        Opmerking: string()
          .default(url.get('Opmerking') || undefined)
          .nullable(),
        ProjectID: string()
          .default(url.get('ProjectID') || undefined)
          .nullable(),
        OrderbevestigingID: string()
          .default(url.get('OrderbevestigingID') || undefined)
          .nullable(),
        GebruikerID: number().default(user.id).nullable()
      });

    case 'invoice_in':
      return object({
        faktuurinvolgnummer: string().default(undefined),
        LeverancierNummer: number()
          .default(parseInt(url.get('LeverancierNummer')) || undefined)
          .required('Supplier is required')
          .nullable(),
        Leveranciernaam: string()
          .default(url.get('Leveranciernaam') || undefined)
          .nullable(),
        faktuurref: string()
          .default(url.get('faktuurref') || undefined)
          .required('Reference is required')
          .nullable(),
        datum: string()
          .default(getCurrenctDatetimeYYYMMDD() || undefined)
          .required('Date invoice is required')
          .nullable()
          .testDate('Date invoice'),
        datumin: string()
          .default(getCurrenctDatetimeYYYMMDD() || undefined)
          .required('Date in is required')
          .nullable()
          .testDate('Date in'),
        datumbetaling: string()
          .default(url.get('datumbetaling') || undefined)
          .required('Expiry date is required')
          .nullable()
          .testDate('Expiry date'),
        datumbetalingout: string()
          .default(url.get('datumbetalingout') || undefined)
          .nullable()
          .testDate('Payment date'),
        Bedragfaktuur: number()
          .transform((value) => {
            if (value && isNaN(parseInt(value))) alert('❌ Total amount needs to be a number');
            return value ? Number(String(value).replace(/,/g, '.')) : null;
          })
          .default(url.get('Bedragfaktuur') || undefined)
          .required('Total amount is required')
          .nullable(),
        bedragopen: number()
          .transform((value) => {
            if (value && isNaN(parseInt(value))) alert('❌ Open amount needs to be a number');
            return value ? Number(String(value).replace(/,/g, '.')) : null;
          })
          .default(url.get('bedragopen') || undefined)
          .nullable(),
        status: boolean().default(url.get('status') || false),
        munt: string()
          .default(url.get('munt') || undefined)
          .nullable(),
        betalingID: number()
          .default(url.get('betalingID') || undefined)
          .required('Payment condition is required')
          .nullable(),
        betalingstermen: string()
          .default(url.get('betalingstermen') || undefined)
          .nullable(),
        memo: string()
          .default(url.get('memo') || undefined)
          .nullable(),
        Incasseren: string()
          .default(url.get('Incasseren') || undefined)
          .nullable(),
        Goedkeuren: boolean().default(url.get('Goedkeuren') || false),
        Naam: string()
          .default(url.get('Naam') || undefined)
          .nullable(),
        OGM: string()
          .default(url.get('OGM') || undefined)
          .nullable(),
        FactuurInNummer: string().default(undefined),
        ValutaID: number().default(19).required('Currency is required'),
        Vervallen: boolean().default(url.get('Vervallen') || false),
        GaatVervallen: boolean().default(url.get('GaatVervallen') || false),
        BestellingTypeID: number()
          .default(url.get('BestellingTypeID') || 1)
          .required('Order type is required'),
        GebruikerID: number().default(user.id).nullable()
      }).updateToPaid();

    case 'creditnote':
      return object({
        CreditnotaID: number().default(undefined),
        FactuurID: number()
          .default(url.get('FactuurID') || undefined)
          .required('Factuur is required'),
        Referentie: string()
          .default(url.get('Referentie') || undefined)
          .nullable(),
        BedragOpen: number()
          .transform((o, v) => Number(String(v).replace(/,/g, '.')))
          .default(url.get('BedragOpen') || undefined)
          .nullable(),
        BedragTotaal: number()
          .transform((o, v) => Number(String(v).replace(/,/g, '.')))
          .default(url.get('BedragTotaal') || undefined)
          .required('Total amount is required, at at least one line in the content')
          .nullable(),
        Opmerking: string()
          .default(url.get('Opmerking') || undefined)
          .nullable(),
        TaalID: number()
          .default(url.get('TaalID') || undefined)
          .required('Taal is required'),
        BTWVerlegdID: number()
          .default(url.get('BTWVerlegdID') || undefined)
          .nullable(),
        Verstuurd: boolean()
          .default(url.get('Verstuurd') || false)
          .nullable(),
        Betaald: boolean()
          .default(url.get('Betaald') || false)
          .nullable(),
        Creditnotanummer: string().default(url.get('Creditnotanummer') || undefined),
        FIRNR: number()
          .default(url.get('FIRNR') || undefined)
          .required('FIRNR is required'),
        Datum: string().default(getCurrenctDatetimeYYYMMDD()).required('Date is required').testDate('Date'),
        TAV: string()
          .default(TAV[url.get('TaalID')] || undefined)
          .nullable(),
        GebruikerID: number().default(user.id).nullable()
      });

    case 'creditnote_in':
      return object({
        CreditnotaINvolgnummer: number().default(undefined),
        LeverancierNummer: number()
          .default(url.get('LeverancierNummer') || undefined)
          .required('Supplier number is required')
          .nullable(),
        Leveranciernaam: string()
          .default(url.get('Leveranciernaam') || undefined)
          .nullable(),
        faktuurref: string()
          .default(url.get('faktuurref') || undefined)
          .required('Reference is required')
          .nullable(),
        datum: string()
          .default(getCurrenctDatetimeYYYMMDD() || undefined)
          .required('Date creditnote is required')
          .nullable()
          .testDate('Date creditnote'),
        datumin: string()
          .default(getCurrenctDatetimeYYYMMDD() || undefined)
          .required('Date in is required')
          .nullable()
          .testDate('Date in'),
        datumbetaling: string()
          .default(url.get('datumbetaling') || undefined)
          .required('Expiry date is required')
          .nullable()
          .testDate('Expiry date'),
        datumbetalingout: string()
          .default(url.get('datumbetalingout') || undefined)
          .nullable()
          .testDate('Payment date'),
        Bedragfaktuur: number()
          .transform((value) => {
            if (value && isNaN(parseInt(value))) alert('❌ Total amount needs to be a number');
            return value ? Number(String(value).replace(/,/g, '.')) : null;
          })
          .default(url.get('bedragfaktuur') || undefined)
          .required('The invoice amount is required')
          .nullable(),
        bedragopen: number()
          .transform((value) => {
            if (value && isNaN(parseInt(value))) alert('❌ Open amount needs to be a number');
            return value ? Number(String(value).replace(/,/g, '.')) : null;
          })
          .default(url.get('bedragopen') || undefined)
          .nullable(),
        status: boolean().default(url.get('status') || false),
        munt: string()
          .default(url.get('munt') || undefined)
          .nullable(),
        betalingID: number()
          .default(url.get('betalingID') || undefined)
          .required('Payment condition is required'),
        betalingstermen: string()
          .default(url.get('betalingstermen') || undefined)
          .nullable(),
        memo: string()
          .default(url.get('memo') || undefined)
          .nullable(),
        Incasseren: string()
          .default(url.get('Incasseren') || undefined)
          .nullable(),
        Goedkeuren: boolean().default(url.get('Goedkeuren') || false),
        Naam: string()
          .default(url.get('Naam') || undefined)
          .nullable(),
        OGM: string()
          .default(url.get('OGM') || undefined)
          .nullable(),
        hyperlinkCreditnotaIN: string()
          .default(url.get('hyperlinkCreditnotaIN') || undefined)
          .nullable(),
        CreditnotaInNummer: string().default(url.get('CreditnotaInNummer') || undefined),
        ValutaID: number().default(19).required('The currency is required'),
        Vervallen: boolean().default(url.get('Vervallen') || false),
        GaatVervallen: boolean().default(url.get('GaatVervallen') || false),
        GebruikerID: number().default(user.id).nullable()
      });

    case 'product':
      return object({
        ProductID: number().nullable(),
        ProductName: string()
          .default(url.get('ProductName') || undefined)
          .nullable(),
        ProductNameNL: string()
          .default(url.get('ProductNameNL') || undefined)
          .required('Productname NL is required')
          .min(3, 'Productname NL must be at least 3 characters long')
          .max(60, 'Productname NL must be at most 60 characters long')
          .required('Productname NL is required'),
        ProductNameFR: string()
          .default(url.get('ProductNameFR') || undefined)
          .required('Productname FR is required')
          .min(3, 'Productname FR must be at least 3 characters long')
          .max(60, 'Productname FR must be at most 60 characters long')
          .required('Productname FR is required'),
        Productref: string()
          .default(url.get('Productref') || undefined)
          .max(50, 'Productref must be at most 50 characters long')
          .nullable(),
        SupplierID: number('Supplier should be set')
          .default(url.get('SupplierID') || undefined)
          .required('Supplier is required'),
        UnitsOnOrder: number()
          .default(url.get('UnitsOnOrder') || undefined)
          .nullable(),
        'Eenheids verkoopprijs': number()
          .transform((o, v) => Number(String(v).replace(/,/g, '.')))
          .typeError('Sellling price should be a number')
          .default(url.get('Eenheids verkoopprijs') || undefined)
          .required('The selling price is required'),
        'Munt verkoopprijs': string()
          .default(url.get('Munt verkoopprijs') || undefined)
          .nullable(),
        'Datum  bijw_ VP': date().default(url.get('Datum  bijw_ VP') || getCurrenctDatetimeYYYMMDD()),
        ReorderLevel: number()
          .default(url.get('ReorderLevel') || undefined)
          .nullable(),
        Discontinued: boolean().default(url.get('Discontinued') || false),
        Eenheidsaankoopprijs: number()
          .transform((o, v) => Number(String(v).replace(/,/g, '.')))
          .typeError('Purchasing price should be a number')
          .default(url.get('Eenheidsaankoopprijs') || undefined)
          .required('The purchase price is required'),
        'Munt aankoopprijs': string()
          .typeError('Munt aankooprpijs should be a string')
          .default(url.get('Munt aankoopprijs') || undefined)
          .nullable(),
        'Datum bijw_AP': date().default(url.get('Datum bijw_AP') || getCurrenctDatetimeYYYMMDD()),
        Omschrijving: string()
          .default(url.get('Omschrijving') || undefined)
          .nullable(),
        Eenheid: string()
          .default(url.get('Eenheid') || undefined)
          .nullable(),
        ProductNameDE: string()
          .default(url.get('ProductNameDE') || undefined)
          .required('Productname DE is required')
          .min(3, 'Productname DE must be at least 3 characters long'),
        ProductNameUK: string()
          .default(url.get('ProductNameUK') || undefined)
          .required('Productname EN is required')
          .min(3, 'Productname EN must be at least 3 characters long'),
        EenheidID: number()
          .required('The unit is required')
          .default(url.get('EenheidID') || 1),
        EenheidAantal: number()
          .required('The qty per unit needs to be set')
          .default(url.get('EenheidAantal') || 1)
          .min(1, 'The qty per unit needs to be set'),
        ParentProductID: number()
          .default(url.get('ParentProductID') || null)
          .nullable(),
        HasDetails: boolean()
          .default(url.get('HasDetails') || false)
          .required('HasDetails is required'),
        HSCode: string()
          .default(url.get('HSCode') || undefined)
          .nullable(),
        Kg: number()
          .typeError('Weight should be set')
          .default(url.get('Kg') || undefined)
          .nullable()
          .transform((o, v) => Number(o ? String(v).replace(/,/g, '.') : null)),
        OnderdelenTypeID: number()
          .default(url.get('OnderdelenTypeID') || undefined)
          .nullable(),
        ExternalQrUrl: string()
          .default(url.get('ExternalQrUrl') || undefined)
          .nullable()
      });

    case 'project':
      return object({
        ID: string().nullable(),
        firnr: string()
          .default(url.get('firnr') || undefined)
          .typeError('The projects customer needs to be set')
          .required('The projects customer needs to be set'),
        supplierID: string()
          .default(url.get('supplierID') || undefined)
          .nullable(),
        onzeref: string()
          .default(url.get('onzeref') || undefined)
          .nullable(),
        refklant: string()
          .default(url.get('refklant') || undefined)
          .nullable(),
        refleverancier: string()
          .default(url.get('refleverancier') || undefined)
          .nullable(),
        omschrijving: string()
          .default(url.get('omschrijving') || undefined)
          .nullable(),
        verloop: string()
          .default(url.get('verloop') || undefined)
          .nullable(),
        statusomschrijving: string()
          .default(url.get('statusomschrijving') || undefined)
          .nullable(),
        '%aankoopkans': number()
          .default(url.get('%aankoopkans') || undefined)
          .required('The buy percentage is required'),
        '%aankoopbijons': number()
          .default(url.get('%aankoopbijons') || undefined)
          .required('The buy percentage us is required'),
        status: number()
          .default(url.get('status') || 0)
          .required('The status is required'),
        'aanvraag offerte': string()
          .default(url.get('aanvraag offerte') || formatDate(new Date()))
          .required('Date request is required')
          .testDate('Date request'),
        offerte: string().default(url.get('offerte')).nullable().testDate('Date offerte'),
        Beslissing: string().default(url.get('Beslissing')).nullable().testDate('Date decision'),
        Leverings: string().default(url.get('Leverings')).nullable().testDate('Date delivery'),
        Einde: string().default(url.get('Einde')).nullable().testDate('Date end'),
        waarde: number()
          .transform((o, v) => Number(String(v).replace(/,/g, '.')))
          .required('You need to set a project value')
          .default(url.get('waarde') || 0),
        historiek: boolean()
          .default(url.get('historiek') || false)
          .nullable(),
        Gebruiker: string()
          .default(url.get('Gebruiker') || user.username)
          .required('The user is required'),
        Projectnummer: string().default(url.get('Projectnummer') || undefined),
        MeldingDatum: date()
          .default(url.get('MeldingDatum') || undefined)
          .nullable(),
        MeldingGebruikerID: string()
          .default(url.get('MeldingGebruikerID') || undefined)
          .nullable(),
        Melding: string()
          .default(url.get('Melding') || undefined)
          .nullable()
      });

    case 'machine':
      return object({
        counter: number().nullable(),
        Firmaid: number()
          .default(url.get('Firmaid') || undefined)
          .required('Company is required'),
        Machinetype: string()
          .default(url.get('Machinetype') || undefined)
          .required('Machine type is required'),
        Serienummer: string()
          .default(url.get('Serienummer') || undefined)
          .nullable(),
        machinemerk: number()
          .default(url.get('machinemerk') || undefined)
          .required('Machine brand is required'),
        opmerking: string()
          .default(url.get('opmerking') || undefined)
          .nullable(),
        indienst: string()
          .default(url.get('indienst') || undefined)
          .nullable()
          .testDate('In service date'),
        uitdienst: string()
          .default(url.get('uitdienst') || undefined)
          .nullable()
          .testDate('Out of service date'),
        bouwjaar: number()
          .typeError('Construction year must be a number')
          .default(url.get('bouwjaar') || undefined)
          .required('Construction year is required'),
        discontinued: boolean()
          .default(url.get('discontinued') || false)
          .nullable(),
        MachineTypeId: number()
          .default(url.get('MachineTypeId') || 1)
          .nullable(),
        OvereenkomstTypeID: number()
          .default(url.get('OvereenkomstTypeID') || 1)
          .nullable(),
        ProjectID: number()
          .default(url.get('ProjectID') || undefined)
          .nullable()
      });

    default:
      return object({
        Kontraktnummer: string().default(undefined),
        Firnr: string().default(url.get('Firnr') || undefined),
        Machinemerk: string()
          .default(url.get('Machinemerk') || null)
          .typeError('Machine brand is required')
          .required('Machine brand is required'),
        Type: string()
          .default(url.get('Type') || null)
          .nullable(),
        serienummer: string()
          .default(url.get('serienummer') || undefined)
          .nullable(),
        aanvangsdatum: date()
          .default(url.get('aanvangsdatum') || getCurrenctDatetimeYYYMMDD())
          .required('Start date is required'),
        periode: string()
          .default(url.get('periode') || 365)
          .nullable()
          .required('Periode is required'),
        memo1: string()
          .default(url.get('memo1') || undefined)
          .nullable(),
        memo2: string()
          .default(url.get('memo2') || undefined)
          .nullable(),
        laatste: string()
          .default(url.get('laatste') || undefined)
          .nullable(),
        Volgende: string()
          .default(url.get('Volgende') || undefined)
          .nullable(),
        SoortContract: string()
          .default(url.get('SoortContract') || undefined)
          .nullable(),
        Faktuurnummer: string()
          .default(url.get('Faktuurnummer') || undefined)
          .nullable(),
        Faktuurdatum: string()
          .default(url.get('Faktuurdatum') || getCurrenctDatetimeYYYMMDD())
          .nullable(),
        OnderhoudscontractTypeID: number()
          .default(url.get('OnderhoudscontractTypeID') || undefined)
          .required('Maintenance contract type is required'),
        Onderhoudscontractnummer: string().default(url.get('Onderhoudscontractnummer') || undefined),
        Bezoeken: number()
          .default(url.get('Bezoeken') || '1')
          .required('Number of visits is required'),
        Verstuurd: boolean().default(url.get('Verstuurd') || false),
        DISCONTINUED: boolean().default(url.get('DISCONTINUED') || false),
        GebruikerID: number().default(user.id).nullable()
      });
  }
};
