import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filters, DataTable, Page, Card, Link, Badge, Tag } from '@shopify/polaris';
import { getTableFooter, truncate, alphabetize, fetchTableData, getUserOrg } from '../../shared/components/functions.jsx';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll, getUserOptions } from '../../shared/components/listFilter.jsx';
import { useUrl } from '../../shared/util/hanldesave';
import { useAuthStore, useLookupStore } from '../../context/useStore.js';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';

export default function MaintenanceContractsPage() {
  const url = useUrl();
  const navigate = useNavigate();
  const machinebrandsOptions = useLookupStore((state) => state.machinebrands)
    .sort((a, b) => alphabetize(a, b, 'merk'))
    .map((brand) => ({ value: brand.counter, label: brand.merk }));
  const companystatuses = useLookupStore((state) => state.companystatuses);
  const users = useLookupStore((state) => state.users);
  const organisations = useLookupStore((state) => state.organisations);
  const servicecontracttypes = useLookupStore((state) => state.servicecontracttypes);
  const { user } = useAuthStore();
  const userOrg = getUserOrg(organisations, user.ORGANISATION_NAME);
  const filteredUsers = getUserOptions(users, userOrg);

  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    sort: { type: 'none', name: 'Sort by', value: url.get('sort') || '' },
    contractType: {
      type: 'multiple',
      name: 'Contract type',
      value: url.get('contractType') || null,
      choices: servicecontracttypes.map((type) => ({ value: type.OnderhoudscontractTypeID, label: type.OnderhoudscontractType }))
    },
    dateFrom: { type: 'date', name: 'Date from', value: url.get('dateFrom') || '' },
    dateTo: { type: 'date', name: 'Date to', value: url.get('dateTo') || '' },
    brand: { type: 'multiple', name: 'Brand', value: url.get('brand') || null, choices: machinebrandsOptions },
    type: { type: 'text', name: 'Type', value: url.get('type') || '' },
    company: { type: 'text', name: 'Company', value: url.get('company') || '' },
    serialnr: { type: 'text', name: 'Serial number', value: url.get('serialnr') || '' },
    invoiceDateFrom: { type: 'date', name: 'Invoice date from', value: url.get('invoiceDateFrom') || '' },
    invoiceDateTo: { type: 'date', name: 'Invoice date to', value: url.get('invoiceDateTo') || '' },
    lastDateFrom: { type: 'date', name: 'Last visit from', value: url.get('lastDateFrom') || '' },
    lastDateTo: { type: 'date', name: 'Last visit to', value: url.get('lastDateTo') || '' },
    nextDateFrom: { type: 'date', name: 'Next visit from', value: url.get('nextDateFrom') || '' },
    nextDateTo: { type: 'date', name: 'Next visit to', value: url.get('nextDateTo') || '' },
    discontinued: {
      type: 'multiple',
      name: 'Discontinued',
      value: url.get('discontinued') || null,
      choices: [
        { value: 1, label: 'Discontinued' },
        { value: 0, label: 'Active' }
      ]
    },
    owner: { type: 'multiple', name: 'Owner', value: url.get('owner') ? JSON.parse(url.get('owner')) : null, choices: filteredUsers }
  });

  const fetchData = async (abortController) => await fetchTableData(page, '/api/maintenance_contracts', setItems, filters, abortController, setFilterLoading);
  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when page/filters change
  }, [page, filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const note = item.memo1 ? String(item.memo1).substring(0, 50) : '';
        return [
          <Link removeUnderline url={'/maintenance_contracts/' + item.Kontraktnummer}>
            {item.Onderhoudscontractnummer}
          </Link>,
          item.OnderhoudscontractTypeID ? <Badge>{servicecontracttypes.find((type) => type.OnderhoudscontractTypeID === item.OnderhoudscontractTypeID).OnderhoudscontractType}</Badge> : '',
          item.aanvangsdatum ? item.aanvangsdatum.substring(0, 10) : '',
          <Link removeUnderline url={'/companies/' + item.Firnr}>
            <div style={{ display: 'flex', gap: 3 }}>
              {item.NAAM}
              {item.KlantStatusID === 2 || item.KlantStatusID === 4 ? <Badge tone="critical">{companystatuses.find((c) => c.KlantStatusID === item.KlantStatusID).Status}</Badge> : ''}
            </div>
          </Link>,
          item.Machinemerk ? <Tag>{machinebrandsOptions.find((brand) => brand.value === item.Machinemerk)?.label}</Tag> : '',
          item.Type,
          item.serienummer,
          item.Faktuurdatum ? item.Faktuurdatum.substring(0, 10) : '',
          item.laatste ? item.laatste.substring(0, 10) : '',
          item.Volgende ? item.Volgende.substring(0, 10) : '',
          note !== 'null' ? truncate(note, 20, false) : '',
          item.Afkorting ? <Badge>{item.Afkorting}</Badge> : '',
          item.Verstuurd ? <Badge tone="success">Sent</Badge> : <Badge tone="critical">Not sent</Badge>,
          item.DISCONTINUED ? <Badge tone="critical">Discontinued</Badge> : <Badge tone="success">Active</Badge>
        ];
      })
    : [];

  const handleSort = (idx, direction) => handleFilterChange(`[${idx},${direction === 'ascending' ? 'a' : 'd'}]`, filters, setFilters, 'sort', navigate, setPage);

  return (
    <Page title="Maintenance contracts" fullWidth>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter maintenance contracts on company, type and serial number"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
          headings={[
            <b>Number</b>,
            <b>Contract type</b>,
            <b>Date</b>,
            <b>Company</b>,
            <b>Brand</b>,
            <b>Type</b>,
            <b>Serial no.</b>,
            <b>Invoice date</b>,
            <b>Laatste</b>,
            <b>Volgende</b>,
            <b>Notes</b>,
            <b>Owner</b>,
            <b>PDF mailed</b>,
            <b>Discontinued</b>
          ]}
          sortable={[true, false, false, false, false, false, false, true, true, false, false, false, false]}
          onSort={handleSort}
          rows={rows}
          footerContent={getTableFooter(rows, page, setPage, 'maintenance_contracts')}
        />
      </Card>
    </Page>
  );
}
