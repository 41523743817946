import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Page, Select as PSelect, Tabs, Card, Layout, Form, TextField, FormLayout, Checkbox, Banner, Button, Link, Box, BlockStack } from '@shopify/polaris';
import ContactsTable from './components/companycontacts.jsx';
import { handleSaveWithSecondPayload, useUrl } from '../../shared/util/hanldesave';
import { validateSchema } from '../../helpers/schemas/schemasHelpers.js';
import validator from 'validator';
import WordExporterModal from '../../shared/components/modals/wordExporterModal';
import VATinput from '../../shared/components/VATinput.jsx';
//import differnt tables
import SalesCompanyPage from './components/SalesCompany.jsx';
import DeliveriesCompany from './components/DeliveriesCompany.jsx';
import PurchasesCompanyPage from './components/PurchasesCompany.jsx';
import InvoicesCompany from './components/InvoiceCompany.jsx';
import InvoicesInCompany from './components/InvoicesInCompany.jsx';
import CompanySelect from '../../shared/components/companySelect.jsx';
import { useLookupStore } from '../../context/useStore.js';
import CustomPhoneInput from '../../shared/components/inputs/phone.jsx';
import CustomEmailInput from '../../shared/components/inputs/email.jsx';
//import CompanyFileBrowser from './components/CompanyFilebrowser.js';
import InquiriesCompanyPage from './components/InquiriesCompany.jsx';
import QuotationsCompanyPage from './components/QuotationsCompany.jsx';
import CreditnotesCompanyPage from './components/CreditNotesCompany.jsx';
import CreditnotesInCompanyPage from './components/CreditnotesInCompany.jsx';
import ProjectsCompanyPage from './components/ProjectsCompany.jsx';
import ProductsCompanyPage from './components/ProductsCompany.jsx';
import MachinesCompany from './components/MachinesCompany';
import JobsCompany from './components/JobsCompany.jsx';
import WorkReceiptsCompany from './components/WorkReceiptsCompany.jsx';
import MaintenanceContractsCompany from './components/MaintenanceContractsCompany.jsx';
import DetailFileBrowser from '../../shared/components/files/DetailFileBrowser.jsx';
import { alphabetize, countryCodeToFlag, formatToCurrency, companyWebRequest, getScoreImg, getRequest } from '../../shared/components/functions.jsx';
import { TheSaveBar } from '../../shared/components/theSaveBar.jsx';
import Toasts from '../../shared/components/toasts/toasts.jsx';
import { AddCompanyModal } from './components/AddCompanyModal.jsx';
import { creditLimitInfo } from '../../shared/creditLimitInfo.js';
import { TabTitle } from '../../shell/helmet.jsx';
import { getSchema } from '../../shared/formSchemas.js';
import EventTimeLine from '../../shared/components/events/eventTimeLine.jsx';
import { LabelPrintModal } from './components/LabelPrintModal.jsx';
import { CardHeading } from '../../shared/components/cards/CardHeading.jsx';
import { CityZipSelect } from './components/CitzZipSelect.jsx';
import Select from 'react-select';
import InteractionsTimeline from '../../shared/components/interactions/InteractionsTimeline.jsx';
// import PerfectMooseCompany from './components/PerfectMoose.jsx';

function TabsOrders({ id }) {
  const url = useUrl();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(url.get('dtab') ? parseInt(url.get('dtab')) : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate({ pathname: window.location.pathname, search: `?tab=3&dtab=${selectedTabIndex}` }, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    {
      id: 'salesorder-in',
      content: 'Sales orders',
      panelID: 'all-customers-content',
      comp: <SalesCompanyPage FIRNR={id}></SalesCompanyPage>
    },
    {
      id: 'purchaseorder-out',
      content: 'Purchase orders',
      panelID: 'accepts-marketing-content',
      comp: <PurchasesCompanyPage FIRNR={id}></PurchasesCompanyPage>
    },
    {
      id: 'order-delivery',
      content: 'Delivery notes',
      panelID: 'accepts-marketing-content',
      comp: <DeliveriesCompany FIRNR={id}></DeliveriesCompany>
    }
  ];

  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        {tabs[selected].comp}
      </Tabs>
    </Card>
  );
}

function TabsPriceRequests({ id }) {
  const url = useUrl();
  const navigate = useNavigate();

  const [selected, setSelected] = useState(url.get('dtab') ? parseInt(url.get('dtab')) : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate({ pathname: window.location.pathname, search: `?tab=2&dtab=${selectedTabIndex}` }, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    {
      id: 'inquiries-tab',
      content: 'Inquiries',
      panelID: 'inquiries-tab',
      comp: <InquiriesCompanyPage FIRNR={id}></InquiriesCompanyPage>
    },
    {
      id: 'quotations',
      content: 'Quotations',
      panelID: 'quotations-tabs',
      comp: <QuotationsCompanyPage FIRNR={id}></QuotationsCompanyPage>
    }
  ];

  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        {tabs[selected].comp}
      </Tabs>
    </Card>
  );
}

function TabsFinancial({ id }) {
  const url = useUrl();
  const navigate = useNavigate();

  const [selected, setSelected] = useState(url.get('dtab') ? parseInt(url.get('dtab')) : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate({ pathname: window.location.pathname, search: `?tab=4&dtab=${selectedTabIndex}` }, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    {
      id: 'invoice-out',
      content: 'Invoices out',
      panelID: 'all-customers-content',
      comp: <InvoicesCompany FIRNR={id}></InvoicesCompany>
    },
    {
      id: 'invoice-in',
      content: 'Invoices in',
      panelID: 'accepts-marketing-content',
      comp: <InvoicesInCompany FIRNR={id}></InvoicesInCompany>
    },
    {
      id: 'credit-note-out',
      content: 'Credit notes out',
      panelID: 'prospects-content',
      comp: <CreditnotesCompanyPage FIRNR={id}></CreditnotesCompanyPage>
    },
    {
      id: 'credit-note-in',
      content: 'Credit notes in',
      panelID: 'repeat-customers-content',
      comp: <CreditnotesInCompanyPage FIRNR={id}></CreditnotesInCompanyPage>
    }
  ];

  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        {tabs[selected].comp}
      </Tabs>
    </Card>
  );
}

function TabsMachines({ id }) {
  const url = useUrl();
  const navigate = useNavigate();

  const [selected, setSelected] = useState(url.get('dtab') ? parseInt(url.get('dtab')) : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate({ pathname: window.location.pathname, search: `?tab=5&dtab=${selectedTabIndex}` }, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    {
      id: 'machines',
      content: 'Machines',
      panelID: 'all-customers-content',
      add: 'Add Machine',
      comp: <MachinesCompany FIRNR={id}></MachinesCompany>
    },
    {
      id: 'maintenance-contracts',
      content: 'Maintenance Contracts',
      panelID: 'accepts-marketing-content',
      add: 'Add Maintenance Contract',
      comp: <MaintenanceContractsCompany FIRNR={id}></MaintenanceContractsCompany>
    }
  ];
  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        {tabs[selected].comp}
      </Tabs>
    </Card>
  );
}

function TabsTechnicians({ id }) {
  const url = useUrl();
  const navigate = useNavigate();

  const [selected, setSelected] = useState(url.get('dtab') ? parseInt(url.get('dtab')) : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate({ pathname: window.location.pathname, search: `?tab=6&dtab=${selectedTabIndex}` }, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    {
      id: 'Jobs',
      content: 'Jobs',
      panelID: 'all-customers-content',
      comp: <JobsCompany FIRNR={id}></JobsCompany>
    },
    {
      id: 'work-receipts',
      content: 'Montagebons',
      panelID: 'work-receipts',
      comp: <WorkReceiptsCompany FIRNR={id}></WorkReceiptsCompany>
    }
  ];

  return (
    <Card>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        {tabs[selected].comp}
      </Tabs>
    </Card>
  );
}

export const getAddCountryBanner = (company) => {
  return company && company.NAAM && !company.LandID ? (
    <Box paddingBlockEnd={'400'}>
      <Banner tone="critical" title="No country is provided.   Please select and save a country for this customer to continue." />
    </Box>
  ) : null;
};

function CompanyPage() {
  let { id } = useParams();
  let url = useUrl();
  const navigate = useNavigate();
  const { companystatuses, countries, languages, paymentconditions, sectors, companytags } = useLookupStore();

  const [savingResult, setSavingResult] = useState(false);
  const [company, setCompany] = useState({});
  const [initialCompany, setInitialCompany] = useState({});
  const [toast, setToast] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [initialTags, setInitialTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagsDirty, setTagsDirty] = useState(false);
  const [selected, setSelected] = useState(url.get('tab') ? parseInt(url.get('tab')) : 0);
  const [briefModalActive, setBriefModalActive] = useState(false);
  const [labelModalActive, setLabelModalActive] = useState(false);
  const [isSmallLabel, setIsSmallLabel] = useState(true);
  const [isEU, setIsEU] = useState(false);
  const [companyWebModalActive, setCompanyWebModalActive] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [financialReportLoading, setFinancialReportLoading] = useState(false);
  const [showLabelPrintModal, setShowLabelPrintModal] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [showCompanySelect, setShowCompanySelect] = useState(false);
  const pageTitle = id === 'new' ? 'Add company' : company.NAAM;
  const companySchema = getSchema('company', false, false, false, isEU);
  paymentconditions?.sort((a, b) => alphabetize(a, b, 'betalingNL'));

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate({ pathname: window.location.pathname, search: `?tab=${selectedTabIndex}` }, { replace: true });
    },
    [navigate]
  );

  async function fetchData() {
    if (id === 'new') {
      setCompany(companySchema.default());
      setInitialCompany(companySchema.default());
      setCompanyWebModalActive(true);
    } else {
      const data = await getRequest('/api/companies/' + id);
      if (!data) {
        setCompany(companySchema.default());
        setInitialCompany(companySchema.default());
        setCompanyWebModalActive(true);
        return;
      }
      setCompany(data);
      setInitialCompany(data);
      setIsDirty(false);
      setIsEU(data.LandID ? countries.find((country) => country.LandId === data.LandID)?.EU : false);

      const data2 = await getRequest(`/api/companies/${id}/tags`);
      if (data2) {
        const companyTags = data2.map((tag) => ({ value: tag.id, label: tag.omschrijving }));
        setTags(companyTags);
        setInitialTags(companyTags);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id, countries]);

  const handleDiscard = () => {
    setCompany(initialCompany);
    setTags(initialTags);
    setIsDirty(false);
    setTagsDirty(false);
  };

  async function handleSave() {
    setSavingResult(true);
    const errorInSchema = await validateSchema(companySchema, company);

    // Add PM dealer validation
    if (company.isPmDealer) {
      if (!company.pmDealerMachinesDiscountPercentage || !company.pmDealerPitchersDiscountPercentage || !company.pmDealerSparePartsDiscountPercentage) {
        alert('All discount percentages must be filled out for Perfect Moose dealers');
        setSavingResult(false);
        return;
      }
    }

    if (!errorInSchema) {
      await handleSaveWithSecondPayload(
        id,
        'companies',
        company.FIRNR,
        'FIRNR',
        company,
        tags,
        setCompany,
        setInitialCompany,
        `companies/${id}/tags`,
        setTags,
        setInitialTags,
        isDirty,
        setIsDirty,
        tagsDirty,
        setTagsDirty,
        setToast,
        setToastError,
        navigate
      );
    } else {
      alert(errorInSchema);
    }
    setSavingResult(false);
  }

  const handleChange = (value, id) => {
    setCompany((company) => ({ ...company, [id]: value }), setIsDirty(true));
  };

  const handleFinanceRefresh = async () => {
    setIsRefreshing(true);
    setErrorMessage(null);
    const response = await companyWebRequest(company.BTW, true);
    console.log('handleFinancialRefresh', response);
    if (response.GetCompanyByVatResult.CompanyResponse) {
      const { Score, CreditLimit, Balances } = response.GetCompanyByVatResult.CompanyResponse;
      setCompany({
        ...company,
        scoreInfo: Score.Value.Info,
        scoreInt: Score.Value.ScoreAsInt,
        creditLimit: CreditLimit.Value.Limit,
        creditCode: CreditLimit.Value.Code,
        closingDate: Balances.Value && Balances.Value.length > 0 ? Balances.Value.Balans[0].BalansData.BalansData[0].Value : null
      });
      setIsDirty(true);
    } else {
      setErrorMessage(response.GetCompanyByVatResult.StatusMessage);
    }
    setIsRefreshing(false);
  };

  const getFinancialReportURL = async () => {
    setFinancialReportLoading(true);
    const response = await companyWebRequest(company.BTW, true);
    if (response.GetCompanyByVatResult.CompanyResponse) {
      const { ReportUrl } = response.GetCompanyByVatResult.CompanyResponse;
      window.open(`${ReportUrl.Value}`, '_blank');
    } else {
      setErrorMessage(response.GetCompanyByVatResult.StatusMessage);
    }
    setFinancialReportLoading(false);
  };

  function RefreshFinancialsBanner() {
    let closing = new Date(company.closingDate);
    let today = new Date();
    let update = new Date(company['Last modif']);
    return (
      (company.closingDate ? update < closing || ((today - closing) / (1000 * 3600 * 24 * 365) > 1 && (today - update) / (1000 * 3600 * 24 * 365) > 1) : !company.scoreInfo) && (
        <Banner tone={errorMessage ? 'critical' : null}>
          <p>
            {errorMessage ? errorMessage + '  ' : 'New financial details might be available.  '}
            <Button variant="plain" loading={isRefreshing} onClick={() => handleFinanceRefresh()}>
              Click to refresh
            </Button>
          </p>
        </Banner>
      )
    );
  }

  function getImageUrl(name) {
    return new URL(`./components/big/${name}.png`, import.meta.url).href;
  }

  const scoreImage = () => {
    let img = getScoreImg(company.scoreInfo, company.scoreInt);
    return (
      <FormLayout.Group>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={getImageUrl(img)} alt="score" />
        </div>
      </FormLayout.Group>
    );
  };

  function socialHandleAction(handle) {
    return handle && { content: <Link target="_blank"> </Link>, onAction: () => window.open(`${handle}`, '_blank') };
  }

  const company_info_address = (
    <>
      <AddCompanyModal active={companyWebModalActive} setActive={setCompanyWebModalActive} setCompany={setCompany} setIsDirty={setIsDirty} />
      <Card>
        <CardHeading
          title="Company information"
          actions={
            id !== 'new'
              ? [
                  {
                    content: 'Open in google maps',
                    onAction: () => window.open(`https://maps.google.com?q=${company.STRAAT_NR} ${countries.filter((country) => country.LandId === company.LandID)[0].Land}`)
                  }
                ]
              : []
          }
        />
        <Form onSubmit={handleSave}>
          <FormLayout>
            <TextField id="NAAM" label="Company name" value={company.NAAM || ''} onChange={handleChange} autoComplete="none" maxLength={255} showCharacterCount disabled={savingResult} />
            <CompanySelect isClearable value={company.FirNrFactuur} id="FirNrFactuur" onChange={handleChange} label="Invoicing address" disabled={savingResult} />
            <FormLayout.Group condensed>
              <PSelect
                label="Status"
                id="KlantStatusID"
                select="select status"
                placeholder="Select status"
                value={parseInt(company.KlantStatusID) || ''}
                disabled={savingResult}
                options={companystatuses?.map((status) => ({ value: status.KlantStatusID, label: status.Status }))}
                onChange={handleChange}
              />
              <TextField maxLength={50} showCharacterCount id="StatusReden" label="Status info" value={company.StatusReden || ''} onChange={handleChange} autoComplete="off" disabled={savingResult} />
            </FormLayout.Group>
            <PSelect
              id="LandID"
              label="Country"
              placeholder="Select country"
              value={parseInt(company.LandID) || ''}
              disabled={savingResult}
              options={countries?.map((count) => ({ value: count.LandId, label: count.Land, prefix: countryCodeToFlag(count.ISO2) }))}
              onChange={handleChange}
            />
            <TextField
              maxLength={255}
              showCharacterCount={company.STRAAT_NR}
              id="STRAAT_NR"
              label="Street & number"
              value={company.STRAAT_NR || ''}
              onChange={handleChange}
              autoComplete="none"
              disabled={savingResult}
            />
            <CityZipSelect company={company} setCompany={setCompany} savingResult={savingResult} setIsDirty={setIsDirty} />
            <PSelect
              id="TaalID"
              label="Language"
              placeholder="Select language"
              value={parseInt(company.TaalID) || ''}
              disabled={savingResult}
              options={languages?.sort((a, b) => alphabetize(a, b, 'Omschrijving')).map((lan) => ({ value: lan.TaalID, label: lan.Omschrijving }))}
              onChange={handleChange}
            />
            <CustomPhoneInput
              id="TEL"
              label="TEL"
              value={company.TEL}
              onChange={handleChange}
              disabled={savingResult}
              countryIso2={company.LandID ? countries?.find((country) => country.LandId === parseInt(company.LandID))?.ISO2 : 'DE'}
            />
            <CustomPhoneInput
              id="FAX"
              label="FAX"
              value={company.FAX}
              onChange={handleChange}
              disabled={savingResult}
              countryIso2={company.LandID ? countries?.find((country) => country.LandId === parseInt(company.LandID))?.ISO2 : 'BE'}
            />
            <CustomPhoneInput
              id="GSM"
              label="GSM"
              value={company.GSM}
              onChange={handleChange}
              disabled={savingResult}
              countryIso2={company.LandID ? countries?.find((country) => country.LandId === parseInt(company.LandID))?.ISO2 : 'BE'}
            />
            <TextField
              maxLength={50}
              showCharacterCount
              labelAction={
                company['web-site'] ? (validator.isURL(company['web-site']) ? { content: 'Visit website', onAction: () => window.open(`https://www.${company['web-site']}`, '_blank') } : null) : null
              }
              id="web-site"
              label="Website"
              value={company['web-site'] || ''}
              onChange={handleChange}
              autoComplete="off"
              disabled={savingResult}
              error={company['web-site']?.length > 5 ? (validator.isURL(company['web-site']) ? false : true) : false}
              helpText={company['web-site']?.length > 5 ? (validator.isURL(company['web-site']) ? false : 'Website not valid') : false}
            />
            <CustomEmailInput id="E-mail" label="Email" value={company['E-mail']} onChange={handleChange} />

            <FormLayout.Group>
              <Checkbox label="VAT" id="BTWPlichtig" checked={company.BTWPlichtig} onChange={handleChange} disabled={savingResult} />
              <VATinput id="BTW" vat={company.BTW} onChange={handleChange} disabled={!company.BTWPlichtig || savingResult} isEU={isEU} companyLand={company.LandID} />
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox label="Customer" id="Klant" checked={company.Klant} onChange={handleChange} disabled={savingResult} />
              <PSelect
                placeholder="Payment condition"
                disabled={!company.Klant || savingResult}
                id="betalingID"
                value={parseInt(company.betalingID) || ''}
                options={paymentconditions?.map((cond) => ({ value: cond.betalingID, label: cond.betalingNL }))}
                onChange={handleChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox label="Supplier" id="Leverancier" checked={company.Leverancier} onChange={handleChange} disabled={savingResult} />
              <PSelect
                placeholder="Payment condition"
                disabled={!company.Leverancier || savingResult}
                id="LevBetalingID"
                value={parseInt(company.LevBetalingID) || ''}
                options={paymentconditions?.map((cond) => ({ value: cond.betalingID, label: cond.betalingNL }))}
                onChange={handleChange}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Checkbox label="Particulier" id="Particulier" checked={company.Particulier} onChange={handleChange} disabled={savingResult} />
              <PSelect
                placeholder="Payment condition"
                disabled={!company.Particulier || savingResult}
                id="PartBetalingID"
                value={parseInt(company.PartBetalingID) || ''}
                options={paymentconditions?.map((cond) => ({ value: cond.betalingID, label: cond.betalingNL }))}
                onChange={handleChange}
              />
            </FormLayout.Group>
            <PSelect
              placeholder="Sector"
              label="Sector"
              id="SectorID"
              value={parseInt(company.SectorID) || ''}
              disabled={savingResult}
              options={sectors?.sort((a, b) => alphabetize(a, b, 'SectorCode')).map((sect) => ({ value: sect.SectorID, label: `${sect.SectorCode} | ${sect.Sector}` }))}
              onChange={handleChange}
            />
            <TextField maxLength={50} showCharacterCount id="Artikel" label="Article" value={company.Artikel || ''} onChange={handleChange} autoComplete="off" disabled={savingResult} />
            <TextField
              id="personeelsbestand"
              label="Workforce"
              type="number"
              value={company.personeelsbestand ? String(company?.personeelsbestand) : ''}
              onChange={handleChange}
              autoComplete="off"
              disabled={savingResult}
            />
            <FormLayout.Group>
              <TextField
                id="instagramHandle"
                label="Instagram"
                value={company.instagramHandle || ''}
                onChange={handleChange}
                autoComplete="off"
                disabled={savingResult}
                labelAction={socialHandleAction(company.instagramHandle)}
              />
              <TextField
                id="twitterHandle"
                label="Twitter"
                value={company.twitterHandle || ''}
                onChange={handleChange}
                autoComplete="off"
                labelAction={socialHandleAction(company.twitterHandle)}
                disabled={savingResult}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                id="facebookHandle"
                label="Facebook"
                value={company.facebookHandle || ''}
                onChange={handleChange}
                autoComplete="off"
                labelAction={socialHandleAction(company.facebookHandle)}
                disabled={savingResult}
              />
              <TextField
                id="linkedinHandle"
                label="LinkedIn"
                value={company.linkedinHandle || ''}
                onChange={handleChange}
                autoComplete="off"
                labelAction={socialHandleAction(company.linkedinHandle)}
                disabled={savingResult}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                id="youtubeHandle"
                label="YouTube"
                value={company.youtubeHandle || ''}
                onChange={handleChange}
                autoComplete="off"
                labelAction={socialHandleAction(company.youtubeHandle)}
                disabled={savingResult}
              />
              <TextField
                id="tiktokHandle"
                label="TikTok"
                value={company.tiktokHandle || ''}
                onChange={handleChange}
                autoComplete="off"
                labelAction={socialHandleAction(company.tiktokHandle)}
                disabled={savingResult}
              />
            </FormLayout.Group>

            <FormLayout.Group>
              <Checkbox label="Perfect Moose Dealer" id="isPmDealer" checked={company.isPmDealer} onChange={handleChange} disabled={savingResult} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                id="pmDealerMachinesDiscountPercentage"
                label="PM Machines Discount %"
                type="number"
                step="0.01"
                disabled={!company.isPmDealer || savingResult}
                value={company.pmDealerMachinesDiscountPercentage || ''}
                onChange={handleChange}
                autoComplete="off"
              />
              <TextField
                id="pmDealerPitchersDiscountPercentage"
                label="PM Pitchers Discount %"
                type="number"
                step="0.01"
                disabled={!company.isPmDealer || savingResult}
                value={company.pmDealerPitchersDiscountPercentage || ''}
                onChange={handleChange}
                autoComplete="off"
              />
              <TextField
                id="pmDealerSparePartsDiscountPercentage"
                label="PM Spare Parts Discount %"
                type="number"
                step="0.01"
                disabled={!company.isPmDealer || savingResult}
                value={company.pmDealerSparePartsDiscountPercentage || ''}
                onChange={handleChange}
                autoComplete="off"
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Card>
    </>
  );

  const company_info_remarks = (
    <BlockStack gap="400">
      <Card>
        <CardHeading title="Company tags" />
        {id === 'new' ? (
          'Create company before assigning tags'
        ) : (
          <Select
            isMulti
            options={companytags?.sort((a, b) => alphabetize(a, b, 'omschrijving')).map((tag) => ({ value: tag.id, label: tag.omschrijving }))}
            value={tags}
            isDisabled={savingResult}
            menuPortalTarget={document.body}
            onChange={(props) => {
              setTags(props);
              setTagsDirty(true);
            }}
          />
        )}
      </Card>
      {company.BTW && company.BTW.includes('BE') && (
        <Card>
          <CardHeading
            title="Financial details"
            actions={[
              { content: 'View detailed report', onAction: () => getFinancialReportURL(), loading: financialReportLoading },
              { content: 'Refresh data', onAction: () => handleFinanceRefresh() }
            ]}
          />
          <Form onSubmit={handleSave}>
            <FormLayout>
              <RefreshFinancialsBanner />
              {company.scoreInfo && company.scoreInfo !== 'blanco' ? scoreImage() : null}
              {company.creditLimit === 0 && company.creditCode === 0 ? null : (
                <FormLayout.Group>
                  {company.creditLimit || company.creditLimit === 0 ? <TextField disabled id="creditLimit" label="Credit limit" value={formatToCurrency(String(company.creditLimit))} /> : null}
                  {company.creditCode ? <TextField disabled id="creditCode" label="Credit info" value={creditLimitInfo[company.creditCode]} /> : null}
                </FormLayout.Group>
              )}
            </FormLayout>
          </Form>
        </Card>
      )}

      <InteractionsTimeline resourceType="company" resourceId={id} resourceName={company.NAAM} showContact={true} />
      <Card>
        <CardHeading title="Remarks" />
        <Form onSubmit={handleSave}>
          <FormLayout>
            <TextField id="OPMERKING" label="General remarks" multiline={20} value={company.OPMERKING || ''} onChange={handleChange} disabled={savingResult} />
            <TextField id="Opmerkingen1" label="Installation remarks" multiline={10} value={company.Opmerkingen1 || ''} onChange={handleChange} disabled={savingResult} />
            <TextField id="Opmerkingen2" label="Next visit remarks" multiline={10} value={company.Opmerkingen2 || ''} onChange={handleChange} disabled={savingResult} />
          </FormLayout>
        </Form>
      </Card>

      {id !== 'new' ? (
        <BlockStack gap="400">
          <Card>
            <CardHeading title="Company files" />
            <DetailFileBrowser docTypeId={22} resourceId={null} Klantnummer={company.Klantnummer} docNumber={null} FIRNR={id} docType="letter" heightPxs="500px" />
          </Card>
          <EventTimeLine resourceId={id} resourceName="COMPANY" />
        </BlockStack>
      ) : null}
    </BlockStack>
  );

  const tabs =
    id !== 'new'
      ? [
          {
            id: 'general-info',
            content: 'General',
            comp: (
              <Layout>
                <Layout.Section variant="oneHalf">{company_info_address}</Layout.Section>
                <Layout.Section id="info-remarks">{company_info_remarks}</Layout.Section>
              </Layout>
            )
          },
          {
            id: 'contacts',
            content: 'Contacts',
            comp: (
              <Card tcon="person">
                <CardHeading title="Contacts" />
                <ContactsTable FIRNR={id} />
              </Card>
            )
          },
          {
            id: 'pricereq',
            content: 'Price requests',
            comp: (
              <Layout>
                <Layout.Section>
                  <TabsPriceRequests id={id} />
                </Layout.Section>
              </Layout>
            )
          },
          {
            id: 'orders',
            content: 'Orders',
            comp: (
              <Layout>
                <Layout.Section>
                  <TabsOrders id={id} />
                </Layout.Section>
              </Layout>
            )
          },
          {
            id: 'repeat-customers-1',
            content: 'Invoices',
            comp: (
              <Layout>
                <Layout.Section>
                  <TabsFinancial id={id} />
                </Layout.Section>
              </Layout>
            )
          },
          {
            id: 'machines',
            content: 'Machines',
            comp: (
              <Layout>
                <Layout.Section>
                  <TabsMachines id={id} />
                </Layout.Section>
              </Layout>
            )
          },
          {
            id: 'jobs',
            content: 'Jobs',
            comp: (
              <Layout>
                <Layout.Section>
                  <TabsTechnicians id={id} />
                </Layout.Section>
              </Layout>
            )
          },
          {
            id: 'products',
            content: 'Products',
            comp: (
              <Card>
                <CardHeading title="Products" />
                <ProductsCompanyPage FIRNR={id} />
              </Card>
            )
          },
          {
            id: 'projects',
            content: 'Projects',
            comp: (
              <Card>
                <CardHeading title="Projects" />
                <ProjectsCompanyPage FIRNR={id} />
              </Card>
            )
          },
          {
            id: 'perfect_moose',
            content: 'Perfect Moose',
            comp: (
              <Card>
                <CardHeading title="Perfect Moose" />
                {/* <PerfectMooseCompany FIRNR={id} /> */}
              </Card>
            )
          }
        ]
      : [
          {
            id: 'general-info',
            content: 'General',
            comp: (
              <Layout>
                <Layout.Section variant="oneHalf">{company_info_address}</Layout.Section>
                <Layout.Section>{company_info_remarks}</Layout.Section>
              </Layout>
            )
          }
        ];

  var discontinued_banner =
    company && company.KlantStatusID === 2 ? (
      <Box paddingBlockEnd="400">
        <Banner tone="critical" title="This company is GESPERD!" />
      </Box>
    ) : company.KlantStatusID === 3 ? (
      <Box paddingBlockEnd="400">
        <Banner tone="info" title="This company is in aanvraag!" />
      </Box>
    ) : company.KlantStatusID === 4 ? (
      <Box paddingBlockEnd="400">
        <Banner tone="warning" title="This company is inactive!" />
      </Box>
    ) : null;

  const addCountryBanner = getAddCountryBanner(company);

  function disableActionOptions(role) {
    if (!company.Klant && !company.Leverancier && !company.Particulier) return true;
    if (role === company.Klant && company.Leverancier) return false;
    return role ? true : false;
  }

  return (
    <Page
      fullWidth
      title={pageTitle}
      subtitle={id !== 'new' ? '#' + company.Klantnummer + (company.WOONPL ? `, ${company.WOONPL}` : '') : ''}
      actionGroups={
        id !== 'new'
          ? [
              {
                title: 'Print',
                disabled: !company.LandID,
                actions: [
                  {
                    content: 'Small label',
                    onAction: () => {
                      setIsSmallLabel(true);
                      setLabelModalActive(true);
                    }
                  },
                  {
                    content: 'Big label',
                    onAction: () => {
                      setIsSmallLabel(false);
                      setLabelModalActive(true);
                    }
                  }
                ]
              },
              {
                title: 'New price requests',
                disabled: !company.LandID,
                actions: [
                  {
                    content: 'New quotation',
                    disabled: disableActionOptions(company.Leverancier),
                    onAction: async () => {
                      if (company.BTW === null && company.FirNrFactuur == null) {
                        alert('Please enter a VAT to continue.');
                      } else {
                        navigate(
                          `/quotations/new?klantnummer=${company.FIRNR}&TaalID=${company.TaalID}&LandID=${company.LandID}&adres=${company.STRAAT_NR}&postkode=${company.POSTNR}&woonplaats=${company.WOONPL}&btwnummer=${company.BTW}`
                        );
                      }
                    }
                  },
                  {
                    content: 'New inquiry',
                    disabled: disableActionOptions(company.Klant || company.Particulier),
                    onAction: async () => navigate(`/inquiries/new?LeverancierID=${company.FIRNR}&TaalID=${company.TaalID}`)
                  }
                ]
              },
              {
                title: 'New order',
                disabled: !company.LandID,
                accessibilityLabel: 'Company actions',
                actions: [
                  {
                    content: 'New sale order',
                    disabled: disableActionOptions(company.Leverancier),
                    onAction: async () => {
                      navigate(
                        `/sales/new?KlantID=${company.FIRNR}&TaalID=${company.TaalID}&LandID=${company.LandID}&FacturatieKlantID=${
                          company.FirNrFactuur ? company.FirNrFactuur : company.FIRNR
                        }&LeveringKlantID=${company.FIRNR}`
                      );
                    }
                  },
                  {
                    content: 'New purchase order',
                    disabled: disableActionOptions(company.Klant),
                    onAction: async () => navigate(`/purchases/new?LeverancierID=${company.FIRNR}&TaalID=${company.TaalID}`)
                  },
                  {
                    content: 'New delivery note',
                    onAction: async () => {
                      let companyName = company.NAAM.replace('&', '%26');
                      navigate(
                        `/delivery_notes/new?klantnummer=${company.FIRNR}&klantnaam=${companyName}&TaalID=${company.TaalID}&LandID=${company.LandID}&adres=${company.STRAAT_NR}&postkode=${company.POSTNR}&woonplaats=${company.WOONPL}`
                      );
                    }
                  }
                ]
              },
              {
                title: 'New invoice',
                disabled: !company.LandID,
                accessibilityLabel: 'Financial documents',
                actions: [
                  {
                    content: 'New invoice OUT',
                    disabled: disableActionOptions(company.Leverancier),

                    onAction: async () => {
                      /*
                      if invoicing address is different, then invoice on correct address
                      should also expose a lot of variables that are not exposed by the frontend
                      such as the address, zip code, city, country, etc.
                    */
                      if (company.BTW == null && company.FirNrFactuur == null) {
                        if (window.confirm('This company does not have a VAT number. Do you want to continue?')) {
                          let companyName = company.NAAM.replace('&', '%26');
                          const queryUrl = `&btwnummer=${company.BTW}&TaalID=${company.TaalID}&betalingID=${company.betalingID}`;
                          const specificUrl = `&klantnaam=${companyName}&adres=${company.STRAAT_NR}&postkode=${company.POSTNR}&woonplaats=${company.WOONPL}&LandID=${company.LandID}`;
                          navigate(`/invoices/new?klantnummer=${company.FIRNR}${queryUrl}${specificUrl}`);
                        }
                      } else {
                        if (company.FirNrFactuur) {
                          //get seperate invoicing company from api
                          const invoicingCompany = await getRequest(`/api/companies/${company.FirNrFactuur}`);

                          if (window.confirm(`ATTENTION: This company has a separate invoicing address, this will create an invoice on ${invoicingCompany.NAAM}`)) {
                            let invoicingCompanyName = invoicingCompany.NAAM.replace('&', '%26');
                            const queryUrl = `&btwnummer=${invoicingCompany.BTW}&TaalID=${invoicingCompany.TaalID}&betalingID=${invoicingCompany.betalingID}`;
                            const specificUrl = `&klantnaam=${invoicingCompanyName}&adres=${invoicingCompany.STRAAT_NR}&postkode=${invoicingCompany.POSTNR}&woonplaats=${invoicingCompany.WOONPL}&LandID=${invoicingCompany.LandID}`;
                            navigate(`/invoices/new?klantnummer=${company.FirNrFactuur}${queryUrl}${specificUrl}`);
                          }
                        } else {
                          let companyName = company.NAAM.replace('&', '%26');
                          const queryUrl = `&btwnummer=${company.BTW}&TaalID=${company.TaalID}&betalingID=${company.betalingID}`;
                          const specificUrl = `&klantnaam=${companyName}&adres=${company.STRAAT_NR}&postkode=${company.POSTNR}&woonplaats=${company.WOONPL}&LandID=${company.LandID}`;
                          navigate(`/invoices/new?klantnummer=${company.FIRNR}${queryUrl}${specificUrl}`);
                        }
                      }
                    }
                  },
                  {
                    content: 'New invoice IN',
                    disabled: disableActionOptions(company.Klant || company.Particulier),
                    onAction: async () => {
                      let companyName = company.NAAM.replace('&', '%26');
                      navigate(`/invoices_in/new?LeverancierNummer=${company.FIRNR}&Leveranciernaam=${companyName}&betalingID=${company.LevBetalingID}`);
                    }
                  },
                  {
                    content: 'New creditnote IN',
                    disabled: disableActionOptions(company.Klant || company.Particulier),
                    onAction: async () => {
                      let companyName = company.NAAM.replace('&', '%26');
                      navigate(`/creditnotes_in/new?LeverancierNummer=${company.FIRNR}&Leveranciernaam=${companyName}`);
                    }
                  }
                ]
              },
              {
                title: 'Others',
                disabled: !company.LandID,
                id: 'Others',
                actions: [
                  { content: 'New contact', onAction: async () => navigate(`/contacts/new?KLANR=${company.FIRNR}&TaalID=${company.TaalID}`) },
                  { content: 'New product', onAction: async () => navigate(`/products/new?SupplierID=${company.FIRNR}`) },
                  { content: 'New project', onAction: async () => navigate(`/projects/new?firnr=${company.FIRNR}`) },
                  { content: 'New machine', onAction: async () => navigate(`/machines/new?Firmaid=${company.FIRNR}`) },
                  {
                    content: 'New montage bon',
                    onAction: async () => {
                      navigate(
                        `/montage_bons/new?FIRNR=${company.FIRNR}&TaalID=${company.TaalID}&LandID=${company.LandID}&adres=${company.STRAAT_NR}&Postcode=${company.POSTNR}&stad=${company.WOONPL}&BTW=${company.BTW}`
                      );
                    }
                  },
                  { content: 'New brief', onAction: async () => setBriefModalActive(true) }
                ]
              }
            ]
          : []
      }
    >
      <TabTitle title={pageTitle} />
      <WordExporterModal company={company} active={briefModalActive} setActive={setBriefModalActive} setToastError={setToastError} />

      {labelModalActive ? <LabelPrintModal active={labelModalActive} setActive={setLabelModalActive} isSmall={isSmallLabel} company={company} /> : null}

      {discontinued_banner}
      {addCountryBanner}
      <TheSaveBar isDirty={isDirty || tagsDirty} handleSave={handleSave} handleDiscard={handleDiscard} savingResult={savingResult} />
      <Toasts toast={toast} setToast={setToast} errorToast={toastError} setErrorToast={setToastError} toastContent="Company saved" />
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {tabs[selected].comp}
      </Tabs>
    </Page>
  );
}

export default CompanyPage;
