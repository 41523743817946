import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { Page, Card, Filters } from '@shopify/polaris';
import { getRequest } from '../../shared/components/functions';
import { useUrl } from '../../shared/util/hanldesave';
import { useNavigate } from 'react-router-dom';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useLookupStore } from '../../context/useStore.js';
import { useDebounce } from '../../shared/components/inputs/useDebounce';

const containerStyle = {
  width: '100%',
  height: '80vh',
  borderRadius: '20px'
};

const center = {
  lat: 51.0543,
  lng: 3.7174
};

const mapOptions = {
  disableDefaultUI: false,
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: true,
  rotateControl: true,
  fullscreenControl: true
};

const MachineMapPage = () => {
  const url = useUrl();
  const navigate = useNavigate();
  const machinebrandsOptions = useLookupStore((state) => state.machinebrands)
    .sort((a, b) => a.merk.localeCompare(b.merk))
    .map((brand) => ({ value: brand.counter, label: brand.merk }));

  const [machines, setMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    customer: { type: 'text', name: 'Customer', value: url.get('customer') || '' },
    brand: { type: 'multiple', name: 'Brand', value: url.get('brand') ? JSON.parse(url.get('brand')) : null, choices: machinebrandsOptions },
    type: { type: 'text', name: 'Type', value: url.get('type') || '' },
    serialNumber: { type: 'text', name: 'Serial number', value: url.get('serialNumber') || '' },
    inServiceDateFrom: { type: 'date', name: 'Date in service from', value: url.get('inServiceDateFrom') || '' },
    inServiceDateTo: { type: 'date', name: 'Date in service to', value: url.get('inServiceDateTo') || '' },
    status: { type: 'status', name: 'Service', value: url.get('status') || '0' },
    zip: { type: 'text', name: 'Zip', value: url.get('zip') || '' }
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDeMVS7tyf8Wav-umrWBcTqeMA-Xa0-Zlc'
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fetchData = async (abortController) => {
    try {
      const queryParams = Object.entries(filters).reduce((acc, [key, filter]) => {
        if (filter.value && filter.value !== '') {
          acc[key] = filter.type === 'multiple' ? JSON.stringify(filter.value) : filter.value;
        }
        return acc;
      }, {});

      const queryString = new URLSearchParams(queryParams).toString();
      const data = await fetch(`/api/machines?page=1&limit=10000&${queryString}`, { signal: abortController.signal });
      const machines = await data.json();

      // Filter out machines without lat/lon coordinates
      const machinesWithCoordinates = machines.filter((machine) => machine.lat && machine.lon && !isNaN(parseFloat(machine.lat)) && !isNaN(parseFloat(machine.lon)));

      setMachines(machinesWithCoordinates);
    } catch (error) {
      console.error('Error fetching machines:', error);
    } finally {
      setFilterLoading(false);
    }
  };

  const debouncedFetchMachines = useDebounce(fetchData, 300);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchMachines(abortController);
    fetchDataWithAbort();
    return () => abortController.abort();
  }, [filters]);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  return (
    <Page fullWidth title="Machine Map">
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, handlePageChange)}
          appliedFilters={applyFilters(filters, setFilters, navigate, handlePageChange)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, handlePageChange)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, handlePageChange)}
          autoComplete="off"
          queryPlaceholder="Filter machines on number, customer, brand, type and serial number"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, handlePageChange)}
        />
      </Card>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          borderRadius: '20px',
          marginTop: '16px'
        }}
      >
        {!isLoaded ? (
          <span>loading</span>
        ) : (
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={8} options={mapOptions} onLoad={onLoad} onUnmount={onUnmount}>
            {machines?.map((machine) => (
              <Marker key={machine.counter} position={{ lat: parseFloat(machine.lat), lng: parseFloat(machine.lon) }} onClick={() => setSelectedMachine(machine)} />
            ))}
            {selectedMachine && (
              <InfoWindow position={{ lat: parseFloat(selectedMachine.lat), lng: parseFloat(selectedMachine.lon) }} onCloseClick={() => setSelectedMachine(null)}>
                <div>
                  <h3 style={{ margin: '0 0 8px 0' }}>Machine #{selectedMachine.counter}</h3>
                  <p style={{ margin: '0 0 4px 0' }}>
                    <strong>Brand:</strong> {selectedMachine.brand || 'N/A'}
                  </p>
                  <p style={{ margin: '0 0 4px 0' }}>
                    <strong>Type:</strong> {selectedMachine.Machinetype || 'N/A'}
                  </p>
                  <p style={{ margin: '0 0 4px 0' }}>
                    <strong>Serial:</strong> {selectedMachine.Serienummer || 'N/A'}
                  </p>
                  <p style={{ margin: '0 0 8px 0' }}>
                    <strong>Customer:</strong> {selectedMachine.company_name || 'N/A'}
                  </p>
                  <a href={`/machines/${selectedMachine.counter}`} style={{ color: '#2c6ecb', textDecoration: 'underline' }}>
                    View Machine Details
                  </a>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
      </div>
    </Page>
  );
};

export default MachineMapPage;
