import React, { useEffect, useState, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { Page, Card, Filters } from '@shopify/polaris';
import { getRequest } from '../../shared/components/functions';
import { useUrl } from '../../shared/util/hanldesave';
import { useNavigate } from 'react-router-dom';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useLookupStore } from '../../context/useStore.js';
import { useDebounce } from '../../shared/components/inputs/useDebounce';

const containerStyle = {
  width: '100%',
  height: '80vh',
  borderRadius: '20px'
};

const center = {
  lat: 51.0543,
  lng: 3.7174
};

const mapOptions = {
  disableDefaultUI: false,
  zoomControl: true,
  mapTypeControl: true,
  scaleControl: true,
  streetViewControl: true,
  rotateControl: true,
  fullscreenControl: true
};

const CustomerMapPage = () => {
  const url = useUrl();
  const navigate = useNavigate();
  const companystatuses = useLookupStore((state) => state.companystatuses);
  const countries = useLookupStore((state) => state.countries);
  const companytags = useLookupStore((state) => state.companytags);

  const choices = countries.map((item) => ({ label: `${item.Land}`, value: `${item.LandId}` }));
  const statusChoices = companystatuses.map((item) => ({ label: `${item.Status}`, value: `${item.KlantStatusID}` }));
  const tagChoices = companytags.map((item) => ({ label: item.omschrijving, value: `${item.id}` }));

  const relations = [
    { label: 'Customer', value: 'Customer' },
    { label: 'Supplier', value: 'Supplier' },
    { label: 'Private', value: 'Private' }
  ];

  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    name: { type: 'text', name: 'Name', value: url.get('name') || '' },
    city: { type: 'text', name: 'City', value: url.get('city') || '' },
    country: { type: 'multiple', name: 'Country', value: url.get('country') ? JSON.parse(url.get('country')) : null, choices: choices },
    relation: { type: 'multiple', name: 'Relationship', value: url.get('relation') ? JSON.parse(url.get('relation')) : null, choices: relations },
    status: { type: 'multiple', name: 'Status', value: url.get('status') ? JSON.parse(url.get('status')) : null, choices: statusChoices },
    tags: { type: 'multiple', name: 'Company tags', value: url.get('tags') ? JSON.parse(url.get('tags')) : null, choices: tagChoices }
  });

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyDeMVS7tyf8Wav-umrWBcTqeMA-Xa0-Zlc'
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const fetchData = async (abortController) => {
    try {
      const queryParams = Object.entries(filters).reduce((acc, [key, filter]) => {
        if (filter.value && filter.value !== '') {
          acc[key] = filter.type === 'multiple' ? JSON.stringify(filter.value) : filter.value;
        }
        return acc;
      }, {});

      const queryString = new URLSearchParams(queryParams).toString();
      const data = await fetch(`/api/companies?${queryString}`, { signal: abortController.signal });
      const customers = await data.json();
      setCustomers(customers);
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setFilterLoading(false);
    }
  };

  const debouncedFetchCustomers = useDebounce(fetchData, 300);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchCustomers(abortController);
    fetchDataWithAbort();
    return () => abortController.abort();
  }, [filters]);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  return (
    <Page fullWidth>
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, handlePageChange)}
          appliedFilters={applyFilters(filters, setFilters, navigate, handlePageChange)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, handlePageChange)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, handlePageChange)}
          autoComplete="off"
          queryPlaceholder="Filter customers on name, number or city"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, handlePageChange)}
        />
      </Card>
      <div
        style={{
          backgroundColor: '#f8f9fa',
          borderRadius: '20px',
          marginTop: '16px'
        }}
      >
        {!isLoaded ? (
          <span>loading</span>
        ) : (
          <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={8} options={mapOptions} onLoad={onLoad} onUnmount={onUnmount}>
            {customers?.map((customer) => (
              <Marker key={customer.FIRNR} position={{ lat: parseFloat(customer.lat), lng: parseFloat(customer.lon) }} onClick={() => setSelectedCustomer(customer)} />
            ))}
            {selectedCustomer && (
              <InfoWindow position={{ lat: parseFloat(selectedCustomer.lat), lng: parseFloat(selectedCustomer.lon) }} onCloseClick={() => setSelectedCustomer(null)}>
                <div>
                  <h3 style={{ margin: '0 0 8px 0' }}>{selectedCustomer.NAAM}</h3>
                  <p style={{ margin: '0 0 8px 0' }}>{selectedCustomer.STRAAT_NR}</p>
                  <a href={`/customers/${selectedCustomer.FIRNR}`} style={{ color: '#2c6ecb', textDecoration: 'underline' }}>
                    View Customer Details
                  </a>
                </div>
              </InfoWindow>
            )}
          </GoogleMap>
        )}
      </div>
    </Page>
  );
};

export default CustomerMapPage;
