import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card, Button, DataTable, Link, Text, BlockStack, Box } from '@shopify/polaris';
import { formatDate, getRequest } from '../../shared/components/functions';
import { useAuthStore } from '../../context/useStore';
import { CardHeading } from '../../shared/components/cards/CardHeading';

export default function PipelinesPage() {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [pipelines, setPipelines] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPipelines = async () => {
      try {
        const response = await getRequest('/api/pipelines');
        setPipelines(response);
      } catch (error) {
        console.error('Error fetching pipelines:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPipelines();
  }, []);

  const rows = pipelines.map((pipeline) => [
    <Link url={`/pipelines/${pipeline.PipelineID}`}>{pipeline.Name}</Link>,
    pipeline.Description || '-',
    pipeline.OwnerName || '-',
    formatDate(pipeline.CreatedAt),
    formatDate(pipeline.UpdatedAt)
  ]);

  return (
    <Page
      title="Pipelines"
      primaryAction={
        <Button primary onClick={() => navigate('/pipelines/new')}>
          Create pipeline
        </Button>
      }
      fullWidth
    >
      <BlockStack gap="400">
        <Card>
          <CardHeading title="All Pipelines" />
          <Box padding="400">
            <DataTable
              columnContentTypes={['text', 'text', 'text', 'text', 'text']}
              headings={['Name', 'Description', 'Owner', 'Created', 'Updated']}
              rows={rows}
              loading={loading}
              emptyState={
                <Box padding="400">
                  <Text as="p" alignment="center">
                    No pipelines found. Create your first pipeline to get started.
                  </Text>
                </Box>
              }
            />
          </Box>
        </Card>
      </BlockStack>
    </Page>
  );
}
