import React, { useState, useEffect } from 'react';
import { Card, DataTable, Button, Link, Badge, Modal, Box, Text, InlineStack, ButtonGroup } from '@shopify/polaris';
import { getRequest } from '../../components/functions';
import { CardHeading } from '../cards/CardHeading';
import InteractionForm from '../../../pages/interactions/components/InteractionForm';
import { interactionTypeGroups } from '../../../pages/interactions/components/InteractionForm';
import { useLookupStore } from '../../../context/useStore.js';

// Add a style tag to the document head to disable pointer events on the modal backdrop
const addModalBackdropStyle = () => {
  const styleId = 'modal-backdrop-style';
  if (!document.getElementById(styleId)) {
    const style = document.createElement('style');
    style.id = styleId;
    style.innerHTML = `
      .Polaris-Modal-Backdrop {
        pointer-events: none !important;
      }
      .Polaris-Modal-Dialog {
        pointer-events: auto !important;
      }
    `;
    document.head.appendChild(style);
  }
};

export default function InteractionsTimeline({ resourceType, resourceId, resourceName, showContact = false }) {
  const [interactions, setInteractions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [editingInteraction, setEditingInteraction] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [error, setError] = useState(null);
  const { users } = useLookupStore();

  // Add the style when the component mounts
  useEffect(() => {
    addModalBackdropStyle();
  }, []);

  useEffect(() => {
    if (resourceId && resourceId !== 'new') {
      fetchInteractions();
      fetchResourceData();
    }
  }, [resourceId, resourceType]);

  const fetchResourceData = async () => {
    if (resourceId === 'new' || !resourceId) return;

    try {
      if (resourceType === 'company') {
        const data = await getRequest(`/api/companies/${resourceId}`);
        if (data) {
          setCompanyData(data);
        }
      } else if (resourceType === 'contact') {
        const data = await getRequest(`/api/contacts/${resourceId}`);
        if (data && data.length > 0) {
          setContactData(data[0]);
        }
      }
    } catch (error) {
      console.error(`Error fetching resource data:`, error);
      setError('Failed to load resource information');
    }
  };

  const fetchInteractions = async () => {
    if (resourceId === 'new' || !resourceId) return;

    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams();

      if (resourceType === 'company') {
        queryParams.set('company_id', resourceId);
      } else if (resourceType === 'contact') {
        queryParams.set('company_contact_id', resourceId);
      }

      queryParams.set('limit', '50');

      const data = await getRequest(`/api/interactions?${queryParams.toString()}`);
      setInteractions(Array.isArray(data.interactions) ? data.interactions : []);
    } catch (error) {
      console.error(`Error fetching interactions for ${resourceType}:`, error);
      setInteractions([]);
      setError('Failed to load interactions');
    } finally {
      setLoading(false);
    }
  };

  const getInteractionBadgeColor = (type) => {
    if (!type) return 'default';

    for (const group of Object.values(interactionTypeGroups)) {
      if (group.types.includes(type)) {
        return group.color;
      }
    }
    return 'default';
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`; // Format: "YYYY-MM-DDTHH:mm"
  };

  const getInitialInteraction = () => {
    const interaction = {
      type: '',
      description: '',
      event_date: getCurrentDateTime(),
      start_time: '',
      end_time: '',
      company_id: '',
      company_contact_id: '',
      title: ''
    };

    if (resourceType === 'company') {
      interaction.company_id = resourceId;
    } else if (resourceType === 'contact') {
      interaction.company_contact_id = resourceId;
      if (contactData?.KLANR) {
        interaction.company_id = contactData.KLANR;
      }
    }

    return interaction;
  };

  const handleInteractionSubmit = async () => {
    await fetchInteractions();
    setIsViewModalOpen(false);
    setIsNewModalOpen(false);
    setEditingInteraction(null);
  };

  if (resourceId === 'new' || !resourceId) {
    return null;
  }

  if (loading && interactions.length === 0) {
    return (
      <Card>
        <Box>
          <Box paddingBlockEnd="200">
            <InlineStack align="space-between">
              <CardHeading title="Interactions" />
              <Button primary onClick={() => setIsNewModalOpen(true)} loading={loading}>
                New Interaction
              </Button>
            </InlineStack>
          </Box>
          <div style={{ padding: '16px' }}>Loading interactions...</div>
        </Box>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <Box>
          <Box paddingBlockEnd="200">
            <InlineStack align="space-between">
              <CardHeading title="Interactions" />
              <Button primary onClick={() => setIsNewModalOpen(true)}>
                New Interaction
              </Button>
            </InlineStack>
          </Box>
          <div style={{ padding: '16px', color: 'rgb(220, 53, 69)' }}>
            {error}. <Link onClick={fetchInteractions}>Try again</Link>
          </div>
        </Box>
      </Card>
    );
  }

  const rows = interactions.length
    ? interactions.map((interaction) => {
        const baseRow = [
          String(interaction.event_date || '')
            .replace('T', ' ')
            .slice(0, 16),
          <Badge tone={getInteractionBadgeColor(interaction.type)}>{interaction.type || 'Unknown'}</Badge>,
          <Link url={`/interactions/${interaction.id}`} monochrome>
            {interaction.title?.length > 40 ? `${interaction.title.slice(0, 40)}...` : interaction.title || 'No title'}
          </Link>,
          users.find((user) => user.GebruikerID === interaction.user_id)?.Afkorting || 'N/A',
          <ButtonGroup>
            <Button
              size="slim"
              onClick={() => {
                setEditingInteraction(interaction);
                setIsViewModalOpen(true);
              }}
            >
              Edit
            </Button>
            <Button
              size="slim"
              onClick={() => {
                window.location.href = `/interactions/${interaction.id}`;
              }}
            >
              View
            </Button>
          </ButtonGroup>
        ];

        if (showContact && resourceType === 'company') {
          baseRow.splice(2, 0, interaction.contact_name ? <Link url={`/contacts/${interaction.contact_id}`}>{interaction.contact_name}</Link> : '-');
        }

        return baseRow;
      })
    : [];

  const headings = ['Date', 'Type'];
  if (showContact && resourceType === 'company') {
    headings.push('Contact');
  }
  headings.push('Title', 'User', 'Actions');

  return (
    <Card>
      <Box>
        <Box paddingBlockEnd="200">
          <InlineStack align="space-between">
            <CardHeading title="Interactions" />
            <Button primary onClick={() => setIsNewModalOpen(true)}>
              New Interaction
            </Button>
          </InlineStack>
        </Box>

        {interactions.length > 0 ? (
          <DataTable columnContentTypes={Array(headings.length).fill('text')} headings={headings} rows={rows} verticalAlign="middle" increasedTableDensity />
        ) : (
          <div style={{ padding: '16px' }}>
            <Text>No interactions found. Click "New Interaction" to create one.</Text>
          </div>
        )}

        <Box paddingBlockStart="400">
          <InlineStack align="end">
            <Link url={`/interactions?${resourceType === 'company' ? 'company_id=' : 'company_contact_id='}${resourceId}`}>View all interactions</Link>
          </InlineStack>
        </Box>
      </Box>

      {/* View/Edit Interaction Modal */}
      <Modal
        open={isViewModalOpen}
        onClose={() => {
          // This will only be triggered by the close button, not by clicking outside
          setIsViewModalOpen(false);
          setEditingInteraction(null);
        }}
        title="View Interaction"
        size="large"
        noScroll
      >
        <Modal.Section>
          <InteractionForm interaction={editingInteraction} onSubmit={handleInteractionSubmit} />
        </Modal.Section>
        <Modal.Section>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                setIsViewModalOpen(false);
                setEditingInteraction(null);
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Section>
      </Modal>

      {/* New Interaction Modal */}
      <Modal open={isNewModalOpen} onClose={() => setIsNewModalOpen(false)} title="New Interaction" size="large" noScroll>
        <Modal.Section>
          <InteractionForm interaction={getInitialInteraction()} isNew={true} onSubmit={handleInteractionSubmit} />
        </Modal.Section>
        <Modal.Section>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => setIsNewModalOpen(false)}>Close</Button>
          </div>
        </Modal.Section>
      </Modal>
    </Card>
  );
}
