import React, { useState, useEffect, useCallback, forwardRef } from 'react';
import { Excalidraw } from '@excalidraw/excalidraw';
import '@excalidraw/excalidraw/index.css';

export const ExcalidrawCanvas = forwardRef((props, ref) => {
  const [canvasData, setCanvasData] = useState(null);

  useEffect(() => {
    if (props.initialData) {
      try {
        const parsedData = typeof props.initialData === 'string' ? JSON.parse(props.initialData) : props.initialData;
        setCanvasData(parsedData);
      } catch (error) {
        console.error('Error parsing canvas data:', error);
      }
    }
  }, [props.initialData]);

  const handleChange = useCallback(
    (elements, appState, files) => {
      const canvasState = {
        elements,
        appState,
        files
      };

      if (props.onChange) {
        props.onChange(canvasState);
      }

      if (props.canvasId) {
        saveCanvas(canvasState);
      }
    },
    [props.onChange, props.canvasId]
  );

  const saveCanvas = async (canvasState) => {
    try {
      const response = await fetch(`/api/canvas/${props.canvasId || ''}`, {
        method: props.canvasId ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          canvas: JSON.stringify(canvasState),
          name: 'Untitled Canvas' // You might want to make this configurable
        })
      });

      if (!response.ok) {
        throw new Error('Failed to save canvas');
      }
    } catch (error) {
      console.error('Error saving canvas:', error);
    }
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Excalidraw ref={ref} initialData={canvasData} onChange={handleChange} />
    </div>
  );
});

ExcalidrawCanvas.displayName = 'ExcalidrawCanvas';
