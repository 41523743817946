import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Page, Card, SkeletonBodyText } from '@shopify/polaris';
import { getRequest, putRequest } from '../../shared/components/functions';
import InteractionForm from '../interactions/components/InteractionForm';

export default function InteractionPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [interaction, setInteraction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchInteraction();
  }, [id]);

  const fetchInteraction = async () => {
    try {
      const data = await getRequest(`/api/interactions/${id}`);
      setInteraction(data);
    } catch (error) {
      console.error('Error fetching interaction:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (updatedData) => {
    try {
      await putRequest(`/api/interactions/${id}`, updatedData);
      await fetchInteraction();
      navigate('/interactions');
    } catch (error) {
      console.error('Error updating interaction:', error);
    }
  };

  if (isLoading) {
    return (
      <Page title="Interaction Details">
        <SkeletonBodyText lines={6} />
      </Page>
    );
  }

  return (
    <Page title={`Edit interaction #${interaction.id}`} backAction={{ content: 'Back to Interactions', onAction: () => navigate('/interactions') }}>
      <Card>
        <InteractionForm interaction={interaction} onSubmit={handleUpdate} />
      </Card>
    </Page>
  );
}
