import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, Card, Banner, Box } from '@shopify/polaris';
import { getRequest, putRequest } from '../../shared/components/functions';
import { Excalidraw } from '@excalidraw/excalidraw';
import '@excalidraw/excalidraw/index.css';

export function CanvasView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [canvas, setCanvas] = useState({ Name: '', canvas: '{}' });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCanvas = async () => {
      try {
        setIsLoading(true);
        const response = await getRequest(`/api/canvas/${id}`);
        setCanvas(response);
        setError(null);
      } catch (err) {
        setError('Failed to load canvas. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCanvas();
  }, [id]);

  const handleCanvasChange = async (canvasData) => {
    try {
      const dataToSave = {
        elements: canvasData,
        appState: canvasData?.appState,
        files: canvasData.files || {}
      };

      await putRequest(`/api/canvas/${id}`, {
        name: canvas.Name,
        canvas: JSON.stringify(dataToSave)
      });
      setError(null);
    } catch (err) {
      setError('Failed to save changes. Your work may not be saved.');
    }
  };

  if (isLoading) {
    return <Page title="Loading canvas..." />;
  }

  let initialData;
  try {
    initialData = JSON.parse(canvas.canvas || '{}');
    if (!Array.isArray(initialData.elements)) {
      initialData.elements = [];
    }
  } catch (err) {
    initialData = { elements: [], appState: {} };
  }

  return (
    <Page fullWidth title={canvas?.Name || 'Untitled Canvas'} breadcrumbs={[{ content: 'All Canvases', onAction: () => navigate('/canvas') }]}>
      {error && (
        <Box paddingBlockEnd="400">
          <Banner status="critical">{error}</Banner>
        </Box>
      )}
      <div
        style={{
          height: '80vh',
          width: '100%'
        }}
      >
        <Excalidraw initialData={initialData} onChange={handleCanvasChange} />
      </div>
    </Page>
  );
}
