import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card, Button, Text, Modal, TextField, Layout, BlockStack, Box, Banner } from '@shopify/polaris';
import { ExcalidrawCanvas } from '../../shared/components/ExcalidrawCanvas';
import { getRequest, postRequest, putRequest, deleteRequest } from '../../shared/components/functions';

export function CanvasPage() {
  const navigate = useNavigate();
  const [canvases, setCanvases] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showNewCanvasModal, setShowNewCanvasModal] = useState(false);
  const [newCanvasName, setNewCanvasName] = useState('');
  const [error, setError] = useState(null);

  // Fetch all canvases
  const fetchCanvases = async () => {
    try {
      setIsLoading(true);
      const response = await getRequest('/api/canvas');
      setCanvases(response);
      setError(null);
    } catch (err) {
      setError('Failed to load canvases. Please try again.');
      console.error('Error fetching canvases:', err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCanvases();
  }, []);

  // Create new canvas
  const handleCreateCanvas = async () => {
    try {
      const response = await postRequest('/api/canvas', {
        name: newCanvasName || 'Untitled Canvas',
        canvas: JSON.stringify({ elements: [], appState: {}, files: {} })
      });

      // Ensure response contains the expected data structure
      const canvasId = response.id;
      if (!canvasId) {
        throw new Error('Canvas ID not found in response');
      }

      setShowNewCanvasModal(false);
      setNewCanvasName('');
      navigate(`/canvas/${canvasId}`);
    } catch (err) {
      setError('Failed to create canvas. Please try again.');
      console.error('Error creating canvas:', err);
    }
  };

  // Delete canvas
  const handleDeleteCanvas = async (canvasId) => {
    if (!window.confirm('Are you sure you want to delete this canvas?')) return;

    try {
      await deleteRequest(`/api/canvas/${canvasId}`);
      fetchCanvases();
    } catch (err) {
      setError('Failed to delete canvas. Please try again.');
      console.error('Error deleting canvas:', err);
    }
  };

  if (isLoading) {
    return <Page title="Loading canvases..." />;
  }

  return (
    <Page
      title="My Canvases"
      primaryAction={{
        content: 'New Canvas',
        onAction: () => setShowNewCanvasModal(true)
      }}
    >
      {error && (
        <Box paddingBlockEnd="400">
          <Banner status="critical">{error}</Banner>
        </Box>
      )}

      <Layout>
        {canvases.map((canvas) => (
          <Layout.Section oneThird key={canvas.id}>
            <Card>
              <BlockStack gap="200">
                <Text variant="headingMd" as="h2">
                  {canvas.Name || 'Untitled Canvas'}
                </Text>
                <Box>
                  <BlockStack gap="200">
                    <Button fullWidth onClick={() => navigate(`/canvas/${canvas.id}`)}>
                      Open Canvas
                    </Button>
                    <Button fullWidth tone="critical" onClick={() => handleDeleteCanvas(canvas.id)}>
                      Delete Canvas
                    </Button>
                  </BlockStack>
                </Box>
              </BlockStack>
            </Card>
          </Layout.Section>
        ))}
      </Layout>

      <Modal
        open={showNewCanvasModal}
        onClose={() => setShowNewCanvasModal(false)}
        title="Create New Canvas"
        primaryAction={{
          content: 'Create',
          onAction: handleCreateCanvas
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: () => setShowNewCanvasModal(false)
          }
        ]}
      >
        <Modal.Section>
          <TextField label="Canvas Name" value={newCanvasName} onChange={setNewCanvasName} placeholder="Untitled Canvas" autoComplete="off" />
        </Modal.Section>
      </Modal>
    </Page>
  );
}
