import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Page, Card, DataTable, Modal, Badge, Link, Button, Filters, ButtonGroup, BlockStack, Pagination } from '@shopify/polaris';
import { getRequest, getTableFooter } from '../../shared/components/functions';
import { useUrl } from '../../shared/util/hanldesave';
import { applyFilters, addFilters, handleFilterChange, removeFilter, handleFiltersClearAll } from '../../shared/components/listFilter.jsx';
import { useDebounce } from '../../shared/components/inputs/useDebounce.js';
import InteractionForm, { interactionTypes, interactionTypeGroups } from './components/InteractionForm';
import { useLookupStore } from '../../context/useStore.js';
import ExcelExporterModal from '../../shared/components/modals/excelExporterModal.jsx';

// Add a style tag to the document head to disable pointer events on the modal backdrop
const addModalBackdropStyle = () => {
  const styleId = 'modal-backdrop-style';
  if (!document.getElementById(styleId)) {
    const style = document.createElement('style');
    style.id = styleId;
    style.innerHTML = `
      .Polaris-Modal-Backdrop {
        pointer-events: none !important;
      }
      .Polaris-Modal-Dialog {
        pointer-events: auto !important;
      }
    `;
    document.head.appendChild(style);
  }
};

export default function InteractionsPage() {
  const newInteraction = new URLSearchParams(window.location.search).get('new');
  const url = useUrl();
  const { users } = useLookupStore();
  const navigate = useNavigate();

  const [interactions, setInteractions] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(newInteraction);
  const [editingInteraction, setEditingInteraction] = useState(null);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({
    q: { type: 'text', name: 'Q', value: url.get('q') || '' },
    company: { type: 'text', name: 'Company', value: url.get('company') || '' },
    contact: { type: 'text', name: 'Contact', value: url.get('contact') || '' },
    company_id: { type: 'text', name: 'Company ID', value: url.get('company_id') || '' },
    company_contact_id: { type: 'text', name: 'Contact ID', value: url.get('company_contact_id') || '' },
    type: {
      type: 'multiple',
      name: 'Type',
      value: url.get('type') ? JSON.parse(url.get('type')) : null,
      choices: interactionTypes
    },
    user_id: {
      type: 'multiple',
      name: 'User',
      value: url.get('user_id') ? JSON.parse(url.get('user_id')) : null,
      choices: users.map((user) => ({ label: user.Afkorting, value: user.GebruikerID }))
    },
    dateFrom: { type: 'date', name: 'Date from', value: url.get('dateFrom') || '' },
    dateTo: { type: 'date', name: 'Date to', value: url.get('dateTo') || '' }
  });

  // Add the style when the component mounts
  useEffect(() => {
    addModalBackdropStyle();
  }, []);

  async function getInteractions() {
    setFilterLoading(true);
    try {
      const queryParams = new URLSearchParams();

      if (filters.q.value) queryParams.set('q', filters.q.value);
      if (filters.company.value) queryParams.set('company', filters.company.value);
      if (filters.contact.value) queryParams.set('contact', filters.contact.value);
      if (filters.company_id.value) queryParams.set('company_id', filters.company_id.value);
      if (filters.company_contact_id.value) queryParams.set('company_contact_id', filters.company_contact_id.value);
      if (filters.type.value) queryParams.set('type', JSON.stringify(filters.type.value));
      if (filters.user_id.value) queryParams.set('user_id', JSON.stringify(filters.user_id.value));
      if (filters.dateFrom.value) queryParams.set('dateFrom', filters.dateFrom.value);
      if (filters.dateTo.value) queryParams.set('dateTo', filters.dateTo.value);
      if (page > 1) queryParams.set('page', page);
      queryParams.set('limit', '50');

      const data = await getRequest(`/api/interactions?${queryParams.toString()}`);
      setInteractions(data.interactions);
      setTotalCount(data.total);
    } catch (error) {
      console.error('Error fetching interactions:', error);
    }
    setFilterLoading(false);
  }

  const debouncedFetchData = useDebounce(getInteractions);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await debouncedFetchData();
      setIsLoading(false);
    };

    fetchData();
  }, [page, filters]);

  const getInteractionBadgeColor = (type) => {
    for (const group of Object.values(interactionTypeGroups)) {
      if (group.types.includes(type)) {
        return group.color;
      }
    }
    return 'default';
  };

  const rows = interactions.map((interaction) => [
    <Link url={`/interactions/${interaction.id}`}>{interaction.id}</Link>,
    String(interaction.event_date).replace('T', ' ').slice(0, 16),
    <Badge tone={getInteractionBadgeColor(interaction.type)}>{interaction.type}</Badge>,
    <Link url={`/companies/${interaction.company_id}`}>{interaction.NAAM}</Link>,
    <Link url={`/contacts/${interaction.contact_id}`}>{interaction.contact_name}</Link>,
    <Link url={`/interactions/${interaction.id}`}>{interaction.title?.length > 75 ? `${interaction.title.slice(0, 75)}...` : interaction.title}</Link>,
    users.find((user) => user.GebruikerID === interaction.user_id)?.Afkorting || 'N/A',
    <ButtonGroup>
      <Button
        size="slim"
        onClick={() => {
          setEditingInteraction(interaction);
          setIsModalOpen(true);
        }}
      >
        Edit
      </Button>
      <Button
        size="slim"
        onClick={() => {
          navigate(`/interactions/${interaction.id}`);
        }}
      >
        View
      </Button>
    </ButtonGroup>
  ]);

  if (isLoading) {
    return (
      <Page title="Interactions">
        <Card>
          <div style={{ display: 'flex', justifyContent: 'center', padding: '2rem' }}>loading...</div>
        </Card>
      </Page>
    );
  }

  return (
    <Page
      fullWidth
      title="Interactions"
      primaryAction={{
        content: 'New interaction',
        onAction: () => {
          setEditingInteraction(null);
          setIsModalOpen(true);
        }
      }}
    >
      <Card>
        <Filters
          queryValue={filters.q.value}
          filters={addFilters(filters, setFilters, navigate, setPage)}
          appliedFilters={applyFilters(filters, setFilters, navigate, setPage)}
          loading={filterLoading}
          onQueryChange={(e) => handleFilterChange(e, filters, setFilters, 'q', navigate, setPage)}
          onQueryClear={() => removeFilter('q', filters, setFilters, navigate, setPage)}
          autoComplete="off"
          queryPlaceholder="Filter interactions by company, contact or notes"
          onClearAll={() => handleFiltersClearAll(filters, setFilters, navigate, setPage)}
        >
          <ExcelExporterModal filters={filters} setFilters={setFilters} page={page} setPage={setPage} />
        </Filters>
        <DataTable
          stickyHeader
          increasedTableDensity
          verticalAlign="middle"
          columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text', 'text']}
          headings={['ID', 'Date', 'Type', 'Company', 'Contact', 'Title', 'User', 'Actions']}
          rows={rows}
          footerContent={
            <div>
              <div style={{ marginBottom: '10px' }}>
                Displaying {rows.length} of {totalCount} interactions
              </div>
              <BlockStack inlineAlign="center">
                <Pagination
                  hasPrevious={page > 1}
                  onPrevious={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                  hasNext={page * 50 < totalCount}
                  onNext={() => {
                    setPage(page + 1);
                  }}
                />
              </BlockStack>
            </div>
          }
        />
      </Card>

      <Modal
        open={isModalOpen}
        onClose={() => {
          // This will only be triggered by the close button, not by clicking outside
          setIsModalOpen(false);
          setEditingInteraction(null);
        }}
        title={editingInteraction ? 'Edit Interaction' : 'New Interaction'}
        size="large"
      >
        <Modal.Section>
          <InteractionForm
            interaction={editingInteraction}
            onSubmit={async () => {
              await getInteractions();
              setIsModalOpen(false);
              setEditingInteraction(null);
            }}
          />
        </Modal.Section>
        <Modal.Section>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                setIsModalOpen(false);
                setEditingInteraction(null);
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Section>
      </Modal>
    </Page>
  );
}
